import Vue from 'vue'

class MemberRepository {

    get(id, mini = false) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/get/' + id + (mini ? '?mini' : ''),
                method: 'get',
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(member) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member',
                method: 'post',
                data: member,
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, member) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/' + id,
                method: 'put',
                data: member,
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    // acceptInvitation(memberId) {
    //     return new Promise((resolve, reject) => {
    //         Vue.auth.fetch({
    //             url: `/api/organization/members/accept/${memberId}`,
    //             method: 'post',
    //         }).then(response => {
    //             resolve(response.data)
    //         }).catch(err => {
    //             reject(err)
    //         })
    //     })
    // }

    deleteMember(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: `/api/organization/members/${id}`,
                method: 'delete',
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    changeMemberActiveStatus(id, state) {
        const action = (state) ? "enable" : "disable"
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: `/api/organization/members/${action}/${id}`,
                method: 'put',
            })
            .then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    checkInvitationStatus(token) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/invitation/check-status-token',
                method: 'post',
                data: {
                    token
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    acceptInvitation(token) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/organization/members/accept',
                method: 'post',
                data: {
                    token
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    declineInvitation(token) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/organization/members/decline',
                method: 'post',
                data: {
                    token
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    checkRegistrationIbanToken(token) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/iban/register-iban-check',
                method: 'post',
                data: {
                    token
                },
            })
                .then(response => {

                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    searchChargingPoints(query) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/charging-points/search',
                method: 'post',
                data: {
                    query
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    attachChargingPoint(memberId, chargingPointId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/attach-charging-point',
                method: 'post',
                data: {
                    memberId,
                    chargingPointId
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    detachChargingPoint(memberId, chargingPointId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/detach-charging-point',
                method: 'post',
                data: {
                    memberId,
                    chargingPointId
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    sendIbanRegistrationRequest(memberId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/update/iban-registration-token',
                method: 'post',
                data: {
                    memberId
                },
            })
                .then(response => {

                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    saveIban(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/register-iban',
                method: 'post',
                data
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    searchRfids(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/tokens/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    attachToken(memberId, tokenId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/attach-token',
                method: 'post',
                data: {
                    memberId,
                    tokenId
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    detachToken(memberId, tokenId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/detach-token',
                method: 'post',
                data: {
                    memberId,
                    tokenId
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    createFromCustomer(organizationId, customerId) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/member/create-from-customer',
                method: 'post',
                data: {
                    organizationId,
                    customerId
                },
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new MemberRepository()