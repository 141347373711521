<template>


  <div class="cp-message" v-if="chargingPoint">
    <div class="cp-fiche">

      <div class="left mt-10">


        <v-row>
          <v-col>
            <v-card >
              <v-card-text>
                <v-row dense class="align-center">
                  <v-col>
                    <v-combobox
                        :label="$t('type')"
                        clearable
                        v-model="filter.actions"
                        small-chips
                        deletable-chips
                        multiple
                        hide-details
                        :items="actionItems"
                        ref="actionSelect"
                    >
                    </v-combobox>
                  </v-col>
                  <v-col class="flex-grow-0 text-center">
                    <v-btn @click="selectAll()"  rounded x-small>All</v-btn>
                    <v-btn class="my-5" @click="selectAuthorizeActions"  rounded  x-small>"Authorize" types only</v-btn>
                    <v-btn @click="selectAllWithExclude(['Heartbeat'])"  rounded  x-small>Without Heartbeat</v-btn>
                  </v-col>
                  <v-col class="text-right flex-grow-0">
                    <v-btn icon @click="refresh">
                      <v-icon>mdi-refresh</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-data-table
                class="usage-list"
                :headers="headers"
                disable-sort
                :items="messages"
                :options.sync="options"
                :server-items-length="total"
                :loading="loading"
                :footer-props="{
                  'items-per-page-options': [
                      10, 50, 100
                  ]
                }"
            >

              <template #item.date="{value}">
                {{ value|formatDate('DD/MM/YYYY [à] HH[:]mm[:]ss') }}
              </template>

              <template #item.from="{item}">
                <span v-if="item.from === 'charging-point'">
                    <v-icon>ico-charging-point</v-icon> > <v-icon>mdi-server</v-icon>
                  </span>
                <span v-else>
                    <v-icon>mdi-server</v-icon> > <v-icon>ico-charging-point</v-icon>
                </span>
              </template>


              <template #item.message="{item}">
                <v-btn x-small @click="openDetail(item)">
                  <v-icon left>mdi-magnify</v-icon>
                  Détail
                </v-btn>
              </template>

            </v-data-table>
          </v-col>
        </v-row>

      </div>

      <div class="right">
        <img src="@images/dessin-borne.svg" />
      </div>

      <v-dialog v-model="showDetail" v-if="currentMessage" max-width="800">
        <v-card>
          <v-card-title>{{ currentMessage.action }} - {{ currentMessage.date|formatDate }}</v-card-title>
          <v-card-text>
            <template v-if="currentMessage.id">
              <h3>Id:</h3>
              <pre>{{ currentMessage.id }}</pre>
            </template>
            <template v-if="currentMessage.message">
              <h3>Message:</h3>
              <pre>{{ currentMessage.message }}</pre>
            </template>
            <template v-if="currentMessage.response">
              <h3>Response:</h3>
              <pre>{{ currentMessage.response }}</pre>
            </template>
          </v-card-text>

          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right">
                <v-btn color="primary" small @click="showDetail = false">
                  <v-icon left>mdi-close</v-icon>
                  Fermer
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      options: null,
      allActions: false,
      headers: [
        {text: 'Date', value: 'date'},
        {value: 'from'},
        {text: 'Action', value: 'action'},
        {text: 'message', value: 'message'},
      ],
      actionItems: [
        'Connect',
        'Disconnect',
        'Error',
        'BootNotification',
        'StatusNotification',
        'Heartbeat',
        'DataTransfer',

        'Authorize',
        'StartTransaction',
        'StopTransaction',
        'RemoteStartTransaction',
        'RemoteStopTransaction',
        'MeterValues',

        'ChangeAvailability',
        'GetConfiguration',
        'ChangeConfiguration',
        'ClearCache',
        'UnlockConnector',
        'Reset',

        'TriggerMessage',
        'GetDiagnostics',
        'DiagnosticsStatusNotification',
        'UpdateFirmware',
        'FirmwareStatusNotification',
      ],
      filter: {
        actions: []
      },
      loading: false,
      total: 0,
      messages: [],
      showDetail: false,
      currentMessage: null,
    }
  },



  mounted() {
    if(!this.currentUser.permissions.includes('STATION_LOG')) {
      this.$router.replace({name: 'charging-points.detail'})
      return
    }

  },

  destroyed() {

  },

  watch: {
    allActions(val) {
      if(val) {
        this.filter.actions = this.actionItems
      } else {
        this.filter.actions = []
      }
    },

    chargingPoint() {

    },
    options() {
      this.loadData()
    },
    filter: {
      handler() {
        if(this.options.page > 1) {
          this.options.page = 1
        } else {
          this.loadData()
        }
      },
      deep: true
    }
  },

  computed:  {
    chargingPoint() {
      return this.$parent.chargingPoint
    },
  },

  methods: {

    refresh() {
      if(this.options.page > 1) {
        this.options.page = 1
      } else {
        this.loadData()
      }
    },

    loadData() {
      this.loading = true;
      let data = this.filter

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      this.$auth.fetch({
        url: '/api/charging-point/messages/' + this.chargingPoint.id,
        method: 'post',
        data,
      }).then(response => {
        this.total = response.data.total
        this.messages = response.data.items
        this.loading = false;
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false;
      })
    },

    openDetail(item) {
      this.currentMessage = item
      this.$nextTick(() => {
        this.showDetail = true;
      })
    },

    selectAuthorizeActions() {
      this.$refs.actionSelect?.blur()
      this.$nextTick(() => {
        this.filter.actions = [
          'Authorize',
          'StartTransaction',
          'StopTransaction',
          'RemoteStartTransaction',
          'RemoteStopTransaction',
        ];
      })
    },

    selectAllWithExclude(excludes) {
      this.$refs.actionSelect?.blur()
      this.$nextTick(() => {
        this.filter.actions = this.actionItems.filter(item => !excludes.includes(item))
      })
    },

    selectAll() {
      this.$refs.actionSelect?.blur()
      this.$nextTick(() => {
        this.filter.actions = []
      })
    }
  }
}
</script>

<style lang="scss">
.cp-message {
  .cp-fiche {
    display: flex;


    .left {
      flex: 1;
    }

    .right {
      flex: 0;
    }

    .append{
      position:sticky;
      bottom:8px;
      width:100%;
      display:flex;
      justify-content :center;
      background :white;

    }

  }
}

</style>
