<template>
  <div v-if="chargingPoint">


    <v-row class="my-5" v-if="currentUser.permissions.includes('ISSUE_WRITE')">
      <v-col class="text-right">
        <v-btn :to="{name: 'charging-points.detail.issues.add'}">
          <v-icon left>mdi-alert-plus-outline</v-icon>
          {{ $t('issues.add')}}
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <date-picker
            :label="$t('period')"
            range
            v-model="periods"
            clearable
        />
      </v-col>
    </v-row>


    <v-divider class="my-6"/>

    <v-card>
      <v-card-text>
        <v-form @submit.prevent="loadData">
          <v-row>
            <v-col md="3">
              <v-select
                  ref="selectTypes"
                  :label="$t('issues.type')"
                  v-model="filters.types"
                  :items="issueTypes"
                  multiple
              >

              </v-select>
            </v-col>


            <v-col md="3">
              <v-select
                  ref="selectStatus"
                  :label="$t('issues.status')"
                  v-model="filters.statues"
                  multiple
                  :items="statusTypes"
              >
              </v-select>
            </v-col>


            <v-col md="3">
              <v-select
                  ref="selectPriorities"
                  :label="$t('issues.priority')"
                  v-model="filters.priorities"
                  multiple
                  :items="priorityTypes"
              >
              </v-select>
            </v-col>

          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <v-divider class="my-6"/>

    <v-row>
      <v-col >
        <v-data-table
            :headers="headers"
            :items="issues"
            :options.sync="options"
            :loading="loading"
            :server-items-length="total"
        >
          <template #item.type="{value}">
            {{ $t('enums.issue-types.' + value)}}
          </template>



          <template #item.status="{value}">
            {{ $t('enums.issue-statues.' + value)}}
          </template>


          <template #item.priority="{value, item}">
            <span :style="{color: issuePriorityColor(item)}">
              {{ $t('enums.issue-priorities.' + value)}}
            </span>
          </template>

          <template #item.subject="{item}">
            <router-link :to="{name: 'charging-points.detail.issues.detail',params: {issueId: item.id} }">
              {{ issueSubject(item) }}
            </router-link>
          </template>

          <template #item.createdBy="{item}">
            {{ item.createdBy ? `${item.createdBy.firstname} ${item.createdBy.lastname}` : $t('automation') }}<br />
            {{ item.createdAt|formatDate }}
          </template>


        </v-data-table>
      </v-col>
    </v-row>

  </div>
</template>

<script>
import dayjs from "dayjs";
import IssueRepository from "@repository/IssueRepository"

export default {
  components: {},
  data() {

    let dates = null

    return {
      loading: false,
      periods: dates,
      filters: {
        statues: [],
        types: [],
        priorities: [],
        dates,
      },
      total: 0,
      issues: [],
      options: null,
    }
  },

  mounted() {

  },

  watch: {
    filters: {
      handler() {
        this.loadData()
      },
      deep: true
    },

    options() {
      this.loadData()
    },

    periods(val) {
      if(val?.length === 2) {
        this.filters.dates = val
      } else {
        this.filters.dates = []
      }
    },

  },

  computed: {

    headers() {
      return [
        {text: this.$t('status'), value: 'status'},
        {text: this.$t('type'), value: 'type'},
        {text: this.$t('issues.subject'), value: 'subject'},
        {text: this.$t('issues.priority'), value: 'priority'},
        {text: this.$t('created-by'), value: 'createdBy'},
      ]
    },

    issueTypes()  {
      return [
        {text: this.$t('enums.issue-types.STATION_OFFLINE'), value: 'STATION_OFFLINE'},
        {text: this.$t('enums.issue-types.STATION_ERROR'), value: 'STATION_ERROR'},
        {text: this.$t('enums.issue-types.SESSION_ZERO_KWH'), value: 'SESSION_ZERO_KWH'},
        {text: this.$t('enums.issue-types.SESSION_OVER_36H'), value: 'SESSION_OVER_36H'},
        {text: this.$t('enums.issue-types.USER_NOTIFY'), value: 'USER_NOTIFY'},
        {text: this.$t('enums.issue-types.SUPERVISOR_NOTIFY'), value: 'SUPERVISOR_NOTIFY'},
      ]
    },

    statusTypes()  {
      return [
        {text: this.$t('enums.issue-statues.NEW'), value: 'NEW'},
        {text: this.$t('enums.issue-statues.OPENED'), value: 'OPENED'},
        {text: this.$t('enums.issue-statues.IN_PROGRESS'), value: 'IN_PROGRESS'},
        {text: this.$t('enums.issue-statues.PENDING'), value: 'PENDING'},
        {text: this.$t('enums.issue-statues.RESOLVED'), value: 'RESOLVED'},
        {text: this.$t('enums.issue-statues.CLOSED'), value: 'CLOSED'},
        {text: this.$t('enums.issue-statues.REJECTED'), value: 'REJECTED'},
      ]
    },

    priorityTypes()  {
      return [
        {text: this.$t('enums.issue-priorities.LOW'), value: 'LOW'},
        {text: this.$t('enums.issue-priorities.NORMAL'), value: 'NORMAL'},
        {text: this.$t('enums.issue-priorities.HIGH'), value: 'HIGH'},
        {text: this.$t('enums.issue-priorities.CRITICAL'), value: 'CRITICAL'},

      ]
    },

    chargingPoint() {
      return this.$parent.chargingPoint ?? null
    },
  },

  methods: {

    loadData() {

      this.loading = true
      let data = {
        statues: this.filters.statues,
        priorities: this.filters.priorities,
        types: this.filters.types,
        chargingPointId: this.$parent.chargingPoint.id,
        dateFrom: this.filters.dates?.length > 0 ? this.filters.dates[0] + ' 00:00:00' : null,
        dateTo: this.filters.dates?.length > 0 ? this.filters.dates[1] + ' 23:59:59' : null,
      };



      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }


      IssueRepository.search(data).then(response => {
        this.total = response.total
        this.issues = response.items
        this.loading = false
      }).catch(error => {
        this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        this.loading = false
      })
    },
  }

}
</script>

<style lang="scss">


</style>
