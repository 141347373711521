<template>
  <v-container class="issues-add">

    <v-breadcrumbs
        :items="[
            {text: $t('issues.issues'), to: {name: 'charging-points.detail.issues'}, exact: true},
            {text: $t('issues.add')},
        ]"
        divider=">"
    ></v-breadcrumbs>


    <v-form ref="form" @submit.prevent="save">

      <headline>{{ $t('issues.issues') }}</headline>

      <v-row>
        <v-col>
          <form-field
              :label="$t('issues.subject')"
              v-model="form.subject"
              :rules="[commonRules.required]"
              :col-field="9"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <form-field
              :label="$t('issues.description')"
              type="wysiwyg"
              v-model="form.description"
              :rules="[commonRules.required]"
              :col-field="9"
          />

        </v-col>
      </v-row>

      <v-row>
        <v-col class="text-right">
          <v-btn type="submit">
              {{ $t('create') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>





  </v-container>
</template>

<script>

import rulesMixin from "@mixins/RulesMixin";
import IssueRepository from "@repository/IssueRepository";



export default {


  data() {

    return {
      form: {
        subject: null,
        description: '',
      },
    }
  },

  mixins: [
      rulesMixin,
  ],

  mounted() {
    if(!this.currentUser.permissions.includes('ISSUE_WRITE')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
  },

  watch: {

  },

  computed: {
    chargingPoint() {
      return this.$parent.chargingPoint ?? null
    },

  },

  methods: {
    save() {

      this.form.chargingPointId = this.chargingPoint.id
      if(this.$refs.form.validate()) {
        IssueRepository.create(this.form).then(result => {
          this.$dialog.notify.success(this.$t('update-success'))
          this.$router.push({name: 'charging-points.detail.issues'})
        }).catch(error => {
          this.$dialog.notify.error(error.response?.data?.message ?? error.message)
        })
      }
    }
  },

}
</script>

<style lang="scss">
  .issues-add {

  }


</style>