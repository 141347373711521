<template>
  <div>
    <headline bg>
      Derniers Avis
    </headline>
  </div>
</template>

<script>
export default {
  name: "ChargingPointDetailInformation"
}
</script>

<style lang="scss">

</style>