 <template>


  <div class="cp-edit" v-if="chargingPoint">
    <v-form class="form-grid" ref="form" @submit.prevent="save">

      <headline>
        {{ $t('charging-point.identity-record') }}
      </headline>

      <v-row>
        <v-col>
          <form-field
              :label="$t('name')"
              v-model="chargingPoint.name"
          />
        </v-col>
        <v-col>

        </v-col>
      </v-row>

      <headline class="mt-10">
        {{ $tc('location.location') }}
      </headline>
      <div>
        <template v-if="chargingPoint.location">
          <v-row>
            <v-col>
              <form-field
                :label="$t('name')"
                :value="chargingPoint.location.name"
                disabled
              />
            </v-col>
            <v-col>
              <form-field
                  :label="$t('custom-id')"
                  :value="chargingPoint.location.customId ? chargingPoint.location.customId : '-' "
                  disabled
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col>
              <form-field
                :label="$t('street')"
                :value="(chargingPoint.location.address.number ? chargingPoint.location.address.number + ' ' : '') + chargingPoint.location.address.street"
                disabled
              />
            </v-col>
            <v-col>
              <form-field
                  :label="$t('city')"
                  :value="chargingPoint.location.address.city"
                  disabled
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col>
              <form-field
                :label="$t('zipcode')"
                :value="chargingPoint.location.address.zipcode"
                disabled
              />
            </v-col>
            <v-col>
              <form-field
                  :label="$t('country')"
                  :value="chargingPoint.location.address.country "
                  disabled
              />
            </v-col>

          </v-row>

        </template>
        <template v-else>
          <v-row>
            <v-col cols="12" class="justify-center">
              {{ $t('location.no-location') }}
            </v-col>
          </v-row>
        </template>

        <v-row>
          <v-col cols="12" class="justify-center">
            <v-btn @click="$refs.locationModal.open()">
              {{ $t('charging-point.edit-location') }}
            </v-btn>
          </v-col>
        </v-row>

      </div>


      <headline class="mt-10">
        {{ $t('charging-point.technical-information') }}
      </headline>
      <div>
        <v-row>
          <v-col>
            <form-field
                :label="$t('charging-point.imei')"
                v-model="chargingPoint.identifier"
            />
          </v-col>
          <v-col>
            <form-field
                :label="$t('charging-point.sim-number')"
                v-model="chargingPoint.imsi"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <form-field
                v-model="chargingPoint.chargingPointType.id"
                :label="$t('model')"
                :items="chargingPointTypes.items ?? []"
                type="select"
                item-value="id"
                :filter="modelFilter"
                readonly
            >
              <template #item="{item}">
                <b>{{ item.vendorLabel }}</b> / {{ item.modelLabel }}
              </template>
              <template #selection="{item}">
                <b>{{ item.vendorLabel }}</b> / {{ item.modelLabel }}
              </template>
            </form-field>

          </v-col>

          <v-col>
            <form-field
                :label="$t('charging-point.iccid-number')"
                v-model="chargingPoint.iccid"
            />
          </v-col>

        </v-row>

        <v-row>
          <v-col>
            <form-field
                :label="$t('charging-point.floor-level')"
                v-model="chargingPoint.floorLevel"
            />
          </v-col>
          <v-col>
            <v-row class="form-field">
              <v-col sm="6" class="label">
                <span>
                  {{ $t('charging-point.energy-provider-kwh-price') }}
                </span>
              </v-col>
              <v-col class="input-wrapper">
                <charging-point-energy-price-module
                    :chargingPointId="chargingPoint.id"
                />
              </v-col>
            </v-row>

          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <form-field
                :label="$t('charging-point.energy-provided-by-operator')"
                v-model="chargingPoint.energyProvidedByOperator"
                type="switch"
            />
          </v-col>

          <v-col>
            <form-field
                type="select"
                :label="$t('charging-point.sim-card')"
                :items="simCardDetailsChoices"
                v-model="simCardDetails"
            >
            </form-field>
          </v-col>

        </v-row>

        <v-row>
          <v-col>
            <form-field
                :label="$t('charging-point.fee-for-service')"
                v-model="chargingPoint.feeForService"
                type="switch"
            />
          </v-col>

          <v-col>
            <form-field
                :label="$t('charging-point.free-usage')"
                v-model="chargingPoint.freeUsage"
                type="switch"
            />
          </v-col>

        </v-row>

        <v-row v-if="currentUser.isZE">
          <v-col cols="6">
            <form-field
              type="select"
              :label="$t('charging-point.capabilities')"
              v-model="chargingPoint.capabilities"
              :items="capabilities"
              item-value="value"
              item-text="label"
              multiple
              chips
            />
          </v-col>
          <v-col cols="6">
            <form-field
                :label="$t('archived')"
                v-model="chargingPoint.archived"
                type="switch"
            />
          </v-col>


        </v-row>
      </div>


      <headline>
        {{ $t('charging-point.charging-points') }}
      </headline>
      <v-row justify="center" class="evses">
        <v-col>
          <div class="evse"  v-for="(evse, i) in chargingPoint.evses" :key="`evse-${i}`">
            <headline dotted>
              {{ $t('charging-point.charging-point') }} {{ i + 1}}
            </headline>
            <div class="mt-6">
              <v-row>
                <v-col>
                  <form-field
                    :label=" $t('custom-id')"
                    v-model="evse.customEvseId"
                  />
                </v-col>
                <v-col>
                  <form-field
                      :label=" $t('charging-point.physical-reference')"
                      v-model="evse.physicalReference"
                  />
                </v-col>
                <v-col>
                  <form-field
                      :label=" $t('charging-point.parking-restriction')"
                      v-model="evse.parkingRestriction"
                      type="select"
                      :items="enumsToList($t('enums.parking-restriction'))"
                  />
                </v-col>
              </v-row>
            </div>
            <v-row justify="center" class="connectors">
              <v-col >
                <v-row
                    v-for="(connector, j) in evse.connectors"
                    :key="'connectorId-' + j"
                    class="connector"
                    align="center"
                >

                  <v-col class="num-col">
                    <v-text-field
                        :label="$t('charging-point.connector-id')"
                        v-model.number="connector.connectorId"
                        :rules="[commonRules.required]"
                        type="number"
                    />
                  </v-col>

                  <v-col>
                    <v-row>
                      <v-col cols="4" lg="3">
                        <v-select
                            :label=" $t('charging-point.connector-type')"
                            v-model="connector.type"
                            :items="enumsToList($t('enums.connector-type'), true)"
                            :rules="[commonRules.required]"
                        />
                      </v-col>

                      <v-col cols="4" lg="2">
                        <v-select
                            :label=" $t('charging-point.format')"
                            v-model="connector.format"
                            :items="enumsToList($t('enums.connector-format'))"
                            :rules="[commonRules.required]"
                        />
                      </v-col>

                      <v-col cols="4" lg="2">
                        <v-select
                            :label=" $t('charging-point.power-type')"
                            v-model="connector.powerType"
                            :items="enumsToList($t('enums.power-type'))"
                            :rules="[commonRules.required]"
                        />
                      </v-col>
                      <v-col cols="3" lg="1">
                        <v-text-field
                            :label=" $t('charging-point.voltage')"
                            v-model.number="connector.voltage"
                            min="0"
                            :rules="[commonRules.required]"
                            type="number"
                        >
                          <template #append>V</template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" lg="1">
                        <v-text-field
                            :label=" $t('charging-point.power')"
                            v-model.number="connector.power"
                            :rules="[commonRules.required]"
                            min="0"
                            type="number"
                        >
                          <template #append>kW</template>
                        </v-text-field>
                      </v-col>

                      <v-col cols="3" lg="1">
                        <v-text-field
                            :label="$t('charging-point.amperage')"
                            v-model.number="connector.amperage"
                            min="0"
                            :rules="[commonRules.required]"
                            type="number"
                        >
                          <template #append>A</template>
                        </v-text-field>

                      </v-col>

                      <v-col cols="3" lg="2">
                        <v-select
                            :label="$t('charging-point.charging-mode')"
                            v-model="connector.chargingMode"
                            :items="enumsToList($t('enums.charging-mode'))"
                            :rules="[commonRules.required]"
                        />
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col class="del-col">
                    <v-btn icon small @click="deleteConnector(i, j)">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn @click="addConnector(i)" small>
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t('charging-point.add-connector') }}
                </v-btn>
              </v-col>
            </v-row>

          </div>

          <v-row>
            <v-col class="text-right">
              <v-btn @click="addEvse()" small>
                <v-icon left>mdi-plus</v-icon>
                {{ $t('charging-point.add-evse') }}
              </v-btn>
            </v-col>
          </v-row>

        </v-col>

      </v-row>

      <v-row>
        <v-col class="d-flex justify-center">
          <v-btn class="mr-5" @click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="primary" type="submit">
            {{ $t('save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>


    <location-modal ref="locationModal" v-if="chargingPoint" :location.sync="chargingPoint.location" />

  </div>
</template>

<script>

import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal";
import ChargingPointRepository from "@repository/ChargingPointRepository";
import RulesMixin from "@mixins/RulesMixin";
import ChargingPointEnergyPriceModule from "@pages/ChargingPoint/Detail/modals/ChargingPointEnergyPriceModule.vue";
export default {
  components: {
    LocationModal,
    ChargingPointEnergyPriceModule,
  },

  mixins: [RulesMixin],

  data() {
    return {
      chargingPointTypes: [],
      simCardDetails: 'no-sim-card',
      simCardDetailsChoices: [
        {text: this.$t("charging-point.no-sim-card") ,isPresent: false, isOperatorProvided: false, value: 'no-sim-card'},
        {text: this.$t("charging-point.sim-card-operator") ,isPresent: true, isOperatorProvided:true,  value: 'sim-card-operator'},
        {text: this.$t("charging-point.sim-card-other") ,isPresent: true, isOperatorProvided: false, value: 'sim-card-other'},
      ]
    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('STATION_WRITE')) {
      this.$router.replace({name: 'charging-points.detail'})
      return
    }
    this.loadChargingPointTypes()

    if(this.chargingPoint) {
      this.simCardDetails = this.simCardDetailsToForm()
    }
  },

  watch: {
    chargingPoint: {
      handler() {
        if(this.chargingPoint) {
          this.simCardDetails = this.simCardDetailsToForm()
          this.setUnsavedChanges()
        }
      },
      deep: true
    }
  },

  computed: {
    chargingPoint() {
      let chargingPoint = this.$parent.chargingPoint
      if(chargingPoint) {
        if(chargingPoint.location) {
          chargingPoint.location.organization = {
            id: chargingPoint.organization?.id
          }
        }
      }
      return chargingPoint
    },


    capabilities() {
      return Object.keys(this.$t('enums.charging-point-capability')).map(key => {
        return {
          value: key,
          label: this.$t('enums.charging-point-capability.' + key),
        }
      })
    },

    isPublic() {
      return !this.chargingPoint.location.private
    },

    connectorTypes() {
      let connectorTypes = {}
      let connectorLabels = []
      this.chargingPoint.evses.forEach(evse => {
        evse.connectors.forEach(connector => {
          if(!connectorTypes[connector.typeName]) {
            connectorLabels.push(connector.typeName)
            connectorTypes[connector.typeName] = 0
          }
          connectorTypes[connector.typeName]++
        })
      })
      connectorLabels.sort()
      return connectorLabels.map(s => {
        if(connectorTypes[s] > 1) {
          s += ' (x' + connectorTypes[s] + ')'
        }
        return s;
      }).join(', ')
    },
  },

  methods: {


    getNextConnector() {
      let maxConnectorId = 0;
      if(this.chargingPoint.evses) {
        this.chargingPoint.evses.forEach(evse => {
          evse.connectors.forEach(connector => {
            if(connector.connectorId > maxConnectorId) {
              maxConnectorId = Number(connector.connectorId)
            }
          })
        })
      }

      return maxConnectorId + 1
    },

    loadChargingPointTypes() {
      ChargingPointRepository.chargingPointTypes()
          .then(items => this.chargingPointTypes = items)
          .catch(err => this.$dialog.notify.error(err.response?.data?.message || err.message))
    },

    cancel() {
      this.$router.replace({name: 'charging-points.detail'})
    },

    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        ChargingPointRepository.update(this.chargingPoint.id, {
          ...this.chargingPoint,
          simCardDetails: this.formToSimCardDetails(),
          locationId: this.chargingPoint.location?.id
        }).then(data => {
          this.$parent.chargingPoint = data
          this.cleanUnsavedChanges()
          this.$router.replace({name: 'charging-points.detail'})
          this.$dialog.notify.success(this.$t('update-success'))
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data.message || err.message)
          this.showLoading(false)
        })
      }
      //
    },

    addEvse() {
      if(!this.chargingPoint.evses) {
        this.$set(this.chargingPoint, 'evses', [])
      }
      this.chargingPoint.evses.push({
        num: this.chargingPoint.evses.length + 1,
        customId: null,
        physicalReference: null,
        parkingRestriction: null,
        connectors: [{connectorId: this.getNextConnector()}]
      })
    },

    deleteEvse(evseIndex) {
      this.chargingPoint.evses.splice(evseIndex, 1)
    },

    addConnector(evseIndex) {
      this.chargingPoint.evses[evseIndex].connectors.push({connectorId: this.getNextConnector()})
    },

    deleteConnector(evseIndex, connectorIndex) {
      this.$dialog.confirm({
        text: this.$t('charging-point.confirm-delete-connector')
      }).then(result => {
        if(result) {
          this.chargingPoint.evses[evseIndex].connectors.splice(connectorIndex, 1)
          if(this.chargingPoint.evses[evseIndex].connectors.length === 0) {
            this.chargingPoint.evses.splice(evseIndex, 1)
          }
        }

      })
    },


    modelFilter(item, queryText, itemText) {
      let search = queryText.toLowerCase().replace(/-/, '').replace(/\s+/, ' ')
      let vendor = item.vendorLabel.toLowerCase().replace(/-/, '')
      let model = item.modelLabel.toLowerCase().replace(/-/, '')

      let show = true
      if(search) {
        let words = search.split(' ')
        for(let i=0 ; i < words.length ; i++) {
          let word = words[i]
          if(vendor.indexOf(word) === -1 && model.indexOf(word) === -1) {
            return false
          }
        }
      }
      return show
    },

    formToSimCardDetails() {
      let data = {
        isPresent: false,
        isOperatorProvided: false
      }
      if(this.simCardDetails === 'sim-card-operator') {
        data.isPresent = true
        data.isOperatorProvided = true
      } else if(this.simCardDetails === 'sim-card-other') {
        data.isPresent = true
      }

      return data
    },

    simCardDetailsToForm() {
      if(!this.chargingPoint?.simCardDetails || !this.chargingPoint.simCardDetails.isPresent) {
        return 'no-sim-card'
      } else if(this.chargingPoint.simCardDetails.isOperatorProvided) {
        return 'sim-card-operator'
      } else {
        return 'sim-card-other'
      }
    }

  }
}
</script>

<style lang="scss">
.cp-edit {
  .connectors {

    .connector {
      .label {
        color: var(--v-primary-base);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
      & + .connector {
        border-top: 1px dotted #ccc;
        margin-top: 10px;
      }
      .num-col {
        max-width: 60px;
        input {
          text-align: center;
        }
      }
      .del-col {
        max-width: 60px;
      }
    }

  }
}

</style>
