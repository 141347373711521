<template>


  <div class="cp-info" v-if="chargingPoint">
    <div class="cp-fiche">
      <div class="left d-none d-lg-flex">

        <div class="sticky">

          <v-row>
            <v-col class="text-center">

              <div  class="text-center">
                <img src="@images/dessin-borne.svg"/>
              </div>

              <v-btn dark rounded color="#758093" :to="{name: 'charging-points.edit'}" v-if="currentUser.permissions.includes('STATION_WRITE')">
                <v-icon left>ico-edit</v-icon>
                {{ $t('charging-point.edit') }}
              </v-btn>


              <v-btn dark rounded color="#758093" v-if="false">
                <v-icon left>ico-exporter</v-icon>
                {{ $t('charging-point.export') }}
              </v-btn>
            </v-col>
          </v-row>
        </div>


      </div>

      <div class="right">
        <headline>
          {{ $t('charging-point.identity-record') }}
        </headline>

        <div class="grid">

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('name') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.name }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('owner') }} :
            </v-col>
            <v-col sm="3">
              <router-link :to="{name: 'organizations.detail', params: {id: chargingPoint.organization.id }}" v-if="chargingPoint.organization">
                {{ chargingPoint.organization.name}}
              </router-link>
              <template v-else>-</template>
            </v-col>
          </v-row>

        </div>

        <headline class="mt-10">
          {{ $tc('location.location') }}
        </headline>
        <div class="grid">


          <template v-if="chargingPoint.location">
            <v-row>
              <v-col class="label" sm="3">
                {{ $t('name') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.name }}
              </v-col>
              <v-col class="label" sm="3">
                {{ $t('custom-id') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.customId ? chargingPoint.location.customId : '-' }}
              </v-col>
            </v-row>


            <v-row>
              <v-col class="label" sm="3">
                {{ $t('organization.organization') }} :
              </v-col>
              <v-col sm="9" class="address">
                {{ chargingPoint.organization ? chargingPoint.organization.name : '-' }}
              </v-col>
            </v-row>


            <v-row>
              <v-col class="label" sm="3">
                {{ $t('address') }} :
              </v-col>
              <v-col sm="3" class="address">
                {{ chargingPoint.location.address.number }} {{ chargingPoint.location.address.street }}
                <img src="@images/locate-cp.png" width="50" @click="$refs.locationModal.open()">
              </v-col>
              <v-col class="label" sm="3">
                {{ $t('zipcode') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.address.zipcode }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label" sm="3">
                {{ $t('city') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.address.city }}
              </v-col>
              <v-col class="label" sm="3">
                {{ $t('country') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.address.country }}
              </v-col>
            </v-row>

            <v-row>
              <v-col class="label" sm="3">
                {{ $t('location.additional-information') }} :
              </v-col>
              <v-col sm="9">
                {{ chargingPoint.location.direction && chargingPoint.location.direction.length > 0 ? chargingPoint.location.direction[0].value : null }}
              </v-col>
            </v-row>



            <v-row>
              <v-col class="label" sm="3">
                {{ $t('opening-hours') }} :
              </v-col>
              <v-col sm="3">
                {{ chargingPoint.location.twentyFourSeven ? '24h/24' : '-'}}
              </v-col>
              <v-col class="label" sm="3">
                {{ $t('private') }} :
              </v-col>
              <v-col sm="3">
                <div class="switch">
                  <v-switch
                      readonly
                      color="#00897E"
                      inset
                      hide-details
                      v-model="chargingPoint.location.private"
                  />
                </div>
              </v-col>
            </v-row>

          </template>
          <template v-else>
            <v-row>
              <v-col cols="12" class="justify-center">
                {{ $t('location.no-location') }}
              </v-col>
            </v-row>
          </template>

        </div>


        <headline class="mt-10">
          {{ $t('charging-point.technical-information') }}
        </headline>
        <div class="grid">
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.imei') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.identifier }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.sim-number') }} :

            </v-col>
            <v-col sm="3">
              {{ chargingPoint.imsi }}
            </v-col>
          </v-row>


          <v-row>
            <v-col class="label" sm="3">
              {{ $t('vendor') }} / {{ $t('model') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="chargingPoint.chargingPointType">
                {{ chargingPoint.chargingPointType.vendorLabel }} / {{ chargingPoint.chargingPointType.modelLabel }}
              </template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.firmware-version') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.firmwareVersion }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.serial-number') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.serialNumber }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.ip-address') }} :

            </v-col>
            <v-col sm="3">
              {{ chargingPoint.remoteAddress }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.iccid-number') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.iccid }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.connector-types') }} :
            </v-col>
            <v-col sm="3">
              {{ connectorTypes }}
            </v-col>

          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('first-connection') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.firstConnection|formatDate($t('format_datetime')) }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('last-connection') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.lastConnection|formatDate($t('format_datetime')) }}
            </v-col>
          </v-row>

          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.floor-level') }} :
            </v-col>
            <v-col sm="3">
              {{ chargingPoint.floorLevel || '-' }}
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.energy-provider-kwh-price') }} :
            </v-col>
            <v-col sm="3">
              <charging-point-energy-price-module
                  :chargingPointId="chargingPoint.id"
                  readonly
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.sim-card') }} :
            </v-col>
            <v-col sm="3">
              <template v-if="!chargingPoint.simCardDetails?.isPresent">
                {{ $t("charging-point.no-sim-card") }}
              </template>
              <template v-else-if="chargingPoint.simCardDetails?.isOperatorProvided">
                {{ $t("charging-point.sim-card-operator") }}
              </template>
              <template v-else>
                {{ $t("charging-point.sim-card-other") }}
              </template>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.energy-provided-by-operator') }} :
            </v-col>
            <v-col sm="3">
              <div class="switch">
                <v-switch
                    readonly
                    color="#00897E"
                    inset
                    hide-details
                    v-model="chargingPoint.energyProvidedByOperator"
                />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="label" sm="3">
              {{ $t('charging-point.fee-for-service') }} :
            </v-col>
            <v-col sm="3">
              <div class="switch">
                <v-switch
                    readonly
                    color="#00897E"
                    inset
                    hide-details
                    v-model="chargingPoint.feeForService"
                />
              </div>
            </v-col>
            <v-col class="label" sm="3">
              {{ $t('charging-point.free-usage') }} :
            </v-col>
            <v-col sm="3">
              <div class="switch">
                <v-switch
                    readonly
                    color="#00897E"
                    inset
                    hide-details
                    v-model="chargingPoint.freeUsage"
                />
              </div>
            </v-col>
          </v-row>
          <v-row v-if="currentUser.isZE && chargingPoint.archived">
            <v-col class="label" sm="3">
              {{ $t('archived') }} :
            </v-col>
            <v-col sm="3">
              <div class="switch">
                <v-switch
                    readonly
                    color="#00897E"
                    inset
                    hide-details
                    v-model="chargingPoint.archived"
                />
              </div>
            </v-col>
          </v-row>
        </div>


        <headline class="mt-16">
          {{ $t('charging-point.charging-points') }}
        </headline>
        <v-row justify="center" class="evses" v-if="!chargingPoint.evses">
          <v-col class="text-center">
            {{ $t('charging-point.no-eveses') }}
          </v-col>
        </v-row>
        <v-row justify="center" class="evses" v-else>
          <v-col cols="12" md="6" v-for="evse in chargingPoint.evses" :key="evse.id">
            <div class="evse">
              <headline dotted>
                {{ $t('charging-point.charging-point') }} {{ evse.num }}  <span v-if="evse.physicalReference" class="physical-reference ml-3">{{ evse.physicalReference }}</span>
              </headline>
              <div class="grid dense small">
                <v-row>
                  <v-col cols="12" md="6" class="label">
                    {{ $t('status') }}
                  </v-col>
                  <v-col>
                    <chip-status :value="evse.status" :charging-point-id="chargingPoint.id" :connector-ids="evse.connectors.map(c => c.connectorId)" class="ml-4" />
                  </v-col>
                </v-row>
                <v-row v-if="evse.evseId">
                  <v-col cols="12" md="6" class="label">
                    {{ $t('roaming-id') }}
                  </v-col>
                  <v-col>
                    {{ evse.evseId }}
                  </v-col>
                </v-row>
                <v-row v-if="evse.customEvseId">
                  <v-col cols="12" md="6" class="label">
                    {{ $t('custom-id') }}
                  </v-col>
                  <v-col>
                    {{ evse.customEvseId }}
                  </v-col>
                </v-row>
                <v-row  v-if="evse.parkingRestriction">
                  <v-col cols="12" md="6" class="label">
                    {{ $t('charging-point.parking-restriction') }}
                  </v-col>
                  <v-col>
                    {{ evse.parkingRestriction ? $t('enums.parking-restriction.' + evse.parkingRestriction) : $t('charging-point.no-parking-restriction') }}
                  </v-col>
                </v-row>
              </div>
              <v-row justify="center" class="connectors">
                <v-col cols="12">
                  <div  class="connector">
                    <div class="grid dense small">
                      <v-row class="headline-title">
                        <v-col class="label">
                          {{ $t('charging-point.connector') }}
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'connectorId-' + i">
                          {{ connector.connectorId }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.connector-type') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'typeName-' + i">

                          <v-tooltip
                              bottom
                              color="primary"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                  v-bind="attrs"
                                  v-on="on"
                              >
                                {{ $t('enums.connector-type.' + connector.type) }}
                              </span>
                            </template>
                            <span>{{ connector.type }}</span>
                          </v-tooltip>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.format') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'format-' + i">
                          {{ $t('enums.connector-format.' + connector.format) }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.power-type') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'powerType-' + i">
                          {{ $t('enums.power-type.' + connector.powerType) }}
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.voltage') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'voltage-' + i">
                          {{ connector.voltage }}V
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.power') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'power-' + i">
                          {{ connector.power }}kW
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.amperage') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'amperage-' + i">
                          {{ connector.amperage }}A
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col class="label">
                          {{ $t('charging-point.charging-mode') }} :
                        </v-col>
                        <v-col v-for="(connector, i) in evse.connectors" :key="'chargingMode-' + i">
                          {{ $t('enums.charging-mode.' + connector.chargingMode) }}
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
          </v-col>

        </v-row>

        <template v-if="chargingPoint.customers.length > 0">
          <headline class="mt-10">
            {{ $tc('charging-point.owner', chargingPoint.customers.length) }}
          </headline>

          <v-row>
            <v-col class="text-center">
              <v-chip
                  v-for="c in chargingPoint.customers"
                  :key="c.id"
                  color="success"
                  text-color="white"
                  class="mx-2"
                  :to="{name: 'customer.detail', params: {id: c.id}}"
              >
                {{ c.firstname }} {{ c.lastname }}
              </v-chip>
            </v-col>
          </v-row>
        </template>

      </div>

    </div>



    <location-modal ref="locationModal" readonly v-if="chargingPoint" :location.sync="chargingPoint.location" />

  </div>
</template>

<script>
import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal";
import {EventBus} from "@includes/EventBus";
import ChargingPointEnergyPriceModule from "@pages/ChargingPoint/Detail/modals/ChargingPointEnergyPriceModule.vue";
export default {
  components: {ChargingPointEnergyPriceModule, LocationModal},


  computed: {
    chargingPoint() {
      return this.$parent.chargingPoint
    },

    isPublic() {
      return !this.chargingPoint.location.private
    },

    connectorTypes() {
      if(!this.chargingPoint.evses) {
        return '-'
      }
      let connectorTypes = {}
      let connectorLabels = []
      this.chargingPoint.evses.forEach(evse => {
        evse.connectors.forEach(connector => {
          if(!connectorTypes[connector.type]) {
            connectorLabels.push(connector.type)
            connectorTypes[connector.type] = 0
          }
          connectorTypes[connector.type]++
        })
      })
      connectorLabels.sort()
      return connectorLabels.map(s => {
        if(connectorTypes[s] > 1) {
          s += ' (' + connectorTypes[s] + ')'
        }
        return s;
      }).join(', ')
    },
  },

  methods: {


  }
}
</script>

<style lang="scss">
  .cp-info {
    .cp-fiche {
      display: flex;
      .charging-point-energy-price-module {
        .v-text-field {
          transform: translateY(-8px);
        }
        .row {
          border-bottom: 0;
          min-height: unset;
        }
      }
      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        .v-btn {
          margin-bottom: 30px;
        }
        .sticky {
          position: sticky;
          top: 180px;
        }
      }
      .right {
        flex: 1;
        .address {
          img {
            cursor: pointer;
          }
        }
        .evses {
          .row {
            margin: -4px;
            &  + .row {
              margin-top: 4px;
            }
            & > .col, & > [class*=col-] {
              padding: 4px;
              &:not(.label) {
                justify-content: center;
              }
            }
          }
          .v-expansion-panel {
            background: white;
          }
          .evse {
            //border: 1px dotted #ccc;
            border-radius: 10px;
            padding: 0 20px;
            .sub-headline {
              margin: 20px 0;
              font-weight: 500;
            }
            .physical-reference {
              border: 2px solid var(--v-primary-base);
              color: var(--v-primary-base);
              border-radius: 15px;
              padding: 0px 10px;
              font-size: 16px;
              line-height: 22px;
              height: 26px;
              font-weight: bold;
              box-shadow: 0 0 15px #e53c225e;

            }
            .connectors {
              .connector {
                margin: 0 30px;
                border-radius: 10px;
                .grid {
                  .headline-title {
                    .col {
                      color: var(--v-primary-base);
                      font-weight: 600;
                      display: flex;
                      align-items: center;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }


</style>
