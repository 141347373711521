<template>
  <div class="charging-point-add pa-10">

    <title-page icon="ico-charging-point">
      {{ $t("charging-point.add-charging-point") }}
    </title-page>



    <v-row>
      <v-col class="left">
        <div  class="text-center">
          <img src="@images/dessin-borne.svg"/>
        </div>
      </v-col>
      <v-col class="right">


        <div class="add-title">
          {{ $t('charging-point.connect-new-charging-point') }}
        </div>

        <div class="add-step">
          {{ $t('step') }} {{ currentStep }}/3
        </div>




        <v-stepper v-model="currentStep">
          <v-stepper-header>


            <v-stepper-icon>
              <v-stepper-step-icon
                  :complete="currentStep >= 1"
                  :icon="currentStep > 1 ? 'ico-charging-point-connected' : 'ico-charging-point-connect'"
                  :complete-icon="currentStep > 1 ? 'ico-charging-point-connected' : 'ico-charging-point-connect'"
              >
              </v-stepper-step-icon>
            </v-stepper-icon>

            <v-divider
              :class="{complete: currentStep >= 2}"
            />

            <v-stepper-icon>
              <v-stepper-step-icon
                  :complete="currentStep >= 2"
                  icon="ico-form"
                  complete-icon="ico-form"
              >
              </v-stepper-step-icon>
            </v-stepper-icon>

            <v-divider
                :class="{complete: currentStep >= 3}"
            />

            <v-stepper-icon>
              <v-stepper-step-icon
                  :complete="currentStep >= 3"
                  icon="ico-yes"
                  complete-icon="ico-yes"
              >
                <div class="finish-text" v-html="$t('charging-point.add-charging-point-finish')"></div>
              </v-stepper-step-icon>
            </v-stepper-icon>

          </v-stepper-header>

          <v-stepper-items>


            <v-stepper-content step="1">


              <v-form ref="searchForm" @submit.prevent="search(searchInput)">

                <v-row class="my-6" >
                  <v-col class="text-center">
                    <form-field
                        ref="searchInput"
                        :label="$t('charging-point.imei')"
                        col-field="9"
                        v-model="searchInput"
                        :rules="[commonRules.required]"
                    />
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                        small
                        color="primary"
                        type="submit"
                    >
                      {{ $t('search') }}
                    </v-btn>
                  </v-col>
                </v-row>

                <div class="add-hint" v-html="$t('charging-point.add-charging-point-hint')"></div>

              </v-form>

            </v-stepper-content>

            <v-stepper-content step="2">

              <v-form v-if="chargingPoint">

                <p class="add-hint">
                  {{ $t('charging-point.not-installed-charging-point-founded', {identifier: chargingPoint.identifier})}}
                </p>


                <headline class="mt-10">
                  {{ $t('charging-point.identity-record') }}
                </headline>
                <v-row>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('charging-point.imei')"
                        v-model="chargingPoint.identifier"
                    />
                  </v-col>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('name')"
                        v-model="chargingPoint.name"
                    />
                  </v-col>
                </v-row>

                <v-row>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('vendor')"
                        v-model="chargingPoint.chargingPointType.vendorLabel"
                    />
                  </v-col>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('model')"
                        v-model="chargingPoint.chargingPointType.modelLabel"
                    />
                  </v-col>
                </v-row>


                <v-row>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('imsi')"
                        v-model="chargingPoint.imsi"
                    />
                  </v-col>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('iccid')"
                        v-model="chargingPoint.iccid"
                    />
                  </v-col>
                </v-row>



                <v-row>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('first-connection')"
                        :value="formatDate(chargingPoint.firstConnection)"
                    />
                  </v-col>
                  <v-col>
                    <form-field
                        readonly
                        :label="$t('evses')"
                        :value="evsesText"
                    />
                  </v-col>
                </v-row>

                <headline class="mt-10">
                  {{ $tc('location.location') }}
                </headline>
                <template v-if="chargingPoint.location">
                  <v-row>
                    <v-col>
                      <form-field
                          :label="$t('name')"
                          :value="chargingPoint.location.name"
                          disabled
                      />
                    </v-col>
                    <v-col>
                      <form-field
                          :label="$t('custom-id')"
                          :value="chargingPoint.location.customId ? chargingPoint.location.customId : '-' "
                          disabled
                      />
                    </v-col>

                  </v-row>

                </template>
                <template v-else>
                  <div class="add-hint">
                    {{ $t('charging-point.add-no-location') }}
                  </div>
                </template>

                <v-row>
                  <v-col cols="12" class="text-center">
                    <v-btn
                        @click="$refs.locationModal.open()"
                        small
                    >
                      {{ $t('charging-point.define-location') }}
                    </v-btn>
                  </v-col>
                </v-row>


                <v-row class="my-6">
                  <v-col class="text-center">
                    <v-btn
                        text
                        @click="currentStep = 1"
                    >
                      {{ $t('cancel') }}
                    </v-btn>

                    <v-btn
                        color="primary"
                        small
                        @click="save"
                        :disabled="!chargingPoint.location"

                    >
                      {{ $t('save') }}
                    </v-btn>


                  </v-col>
                </v-row>

              </v-form>


            </v-stepper-content>

            <v-stepper-content step="3">

              <p class="add-success">
                {{ $t('charging-point.charging-point-installed')}}
              </p>

              <div class="text-center">
                <v-btn
                    color="primary"
                    @click="$router.push({name: 'charging-points.detail', params: {id: chargingPoint.id }})"
                >
                  {{ $t('charging-point.open-charging-point') }}
                </v-btn>
              </div>

            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

      </v-col>
    </v-row>




    <location-modal ref="locationModal" v-if="chargingPoint" :location.sync="chargingPoint.location" />


  </div>
</template>

<script>

import ChargingPointRepository from "@repository/ChargingPointRepository";
import RulesMixin from "@mixins/RulesMixin";
import LocationModal from "@pages/ChargingPoint/Detail/modals/LocationModal";

export default {
  components: {LocationModal},
  data() {
    return {
      currentStep: "1",
      searchInput: null,
      chargingPoint: null,
    }
  },

  mixins: [
      RulesMixin,
  ],


  mounted() {
    if(!this.currentUser.permissions.includes('STATION_ADD')) {
      this.$router.replace({name: 'dashboard'})
      return
    }

    if(this.$route.query.imei) {
      this.searchInput = this.$route.query.imei
      this.search(this.searchInput)
    }

    this.$refs.searchInput.setFocus()
  },

  watch: {

  },

  computed: {
    evsesText() {
      if(this.chargingPoint) {
        return this.chargingPoint.evses.map(e => 'EVSE ' + e.num + ': ('+ e.connectors.map(c => this.$t('enums.connector-type.' + c.type)).join(', ') +')').join(',')
      }
      return '-'
    }
  },

  methods: {
    search(searchText) {
      if(searchText) {
        ChargingPointRepository
            .getNotInstalled(searchText)
            .then(result => {
              this.chargingPoint = result
              this.currentStep = 2
            }).catch(err => {
              let message = err.response?.data?.message || err.message
              if(message === 'charging-point.add-not-exist') {
                this.$dialog.notify.warning(this.$t(message))
              } else {
                this.$dialog.notify.error(this.$t(message))
              }
            })
      }
    },

    save(){
      ChargingPointRepository
          .installChargingPoint(this.chargingPoint.identifier, this.chargingPoint.location.id)
          .then(() => {
            this.currentStep = 3
          }).catch(err => {
            this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
          })
    },
   },
}
</script>

<style lang="scss">
.charging-point-add {
  .left {
    max-width: 300px;
  }
  .right {
    max-width: 90%;

    .add-title {
      text-transform: uppercase;
      color: #758093;
      font-size: 30px;
      font-weight: 300;
    }

    .add-step {
      text-transform: uppercase;
      color: var(--v-primary-base);
      font-weight: bold;
      font-size: 22px;
    }

    .v-stepper {
      box-shadow: none;
      background: transparent;
      .v-stepper__header {
        box-shadow: none;
        .v-stepper__step {
          padding: 24px 20px;
          &:not(.v-stepper__step--complete) {
            .v-stepper__step__step {
              background: transparent !important;
              .v-icon {
                color: #758093;
              }
            }
          }
          .v-stepper__step__step {
            width: 50px;
            height: 50px;
            margin-right: 0;
            .v-icon {
              font-size: 40px;
            }
          }
          .finish-text {
            font-size: 20px;
            margin: 0 15px;
            font-weight: 500;
            text-align: center;
            line-height: 28px;
          }
        }
        .v-divider{
          &.complete {
            border-color: var(--v-primary-base);
            border-width: 2px;
          }
        }
      }
      .add-hint {
        text-align: center;
        font-weight: 300;
      }
      .add-success {
        text-align: center;
        font-weight: 300;
        font-size: 24px;
        margin: 40px;
      }
    }

  }
}




</style>
