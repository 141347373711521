<template>
  <div  v-if="chargingPoint">




    <div class="cp-manage">
      <div class="evses">
        <v-row>
          <template  v-for="(evse, i) in chargingPoint.evses">
            <v-col cols="12" md="5" :key="'l-' + evse.id">
              <div class="connector">
                <v-row>
                  <v-col v-for="connector in evse.connectors" :key="connector.connectorId">
                    <div class="plug">
                      <img src="@images/plug-type-2.png">
                    </div>
                    {{ $t('enums.connector-type.' + connector.type) }}
                  </v-col>
                </v-row>

                <div class="evse-title">
                  EVSE <span>{{ evse.num }}</span>
                </div>
                <div class="type">
                  {{ evse.evseId }}
                </div>
              </div>
              <div class="grid dense">
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('error') }} :
                  </v-col>
                  <v-col sm="6">
                    -
                  </v-col>
                </v-row>




                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('charging-point.charging-start-at') }} :
                  </v-col>
                  <v-col sm="6">
                    <template v-if="sessionForEvse(evse.id).transactionId">
                      {{ sessionForEvse(evse.id).startTime ? formatDate(sessionForEvse(evse.id).startTime, $t('charging-point.evse_format_datetime')) : '-' }} <template v-if="sessionForEvse(evse.id)">#{{ sessionForEvse(evse.id).transactionId }}</template>
                    </template>
                    <template v-else>
                      -
                    </template>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('duration') }} :
                  </v-col>
                  <v-col sm="6">
                    <session-stop-watch :evse="sessionForEvse(evse.id)" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('charging-point.charging-stop-at') }} :
                  </v-col>
                  <v-col sm="6">
                    {{ sessionForEvse(evse.id).stopTime ? formatDate(sessionForEvse(evse.id).stopTime, $t('charging-point.evse_format_datetime')) : '-' }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('charging-point.consumed-energy') }} :
                  </v-col>
                  <v-col sm="6">
                    {{ sessionForEvse(evse.id).energy ? sessionForEvse(evse.id).energy/1000 + ' kWh' : '-' }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $t('charging-point.plug') }} :
                  </v-col>
                  <v-col sm="6">
                    {{ sessionForEvse(evse.id).connectorId ? `${$t('enums.connector-type.' + sessionForEvse(evse.id).connectorType)} (con: ${sessionForEvse(evse.id).connectorId})` : '-' }}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col class="label" sm="6">
                    {{ $tc("customer.customer", 1) }} :
                  </v-col>
                  <v-col sm="6">
                    {{ formatCustomer(evse.id) }}
                  </v-col>
                </v-row>

              </div>
              <v-row class="btn-actions">
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded @click="startSession(evse.id)">
                    {{ $t('charging-point.remote-start-session') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded color="red" @click="stopSession(evse.id)">
                    {{ $t('charging-point.remote-stop-session') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded @click="openUnlockConnector(evse.id)">
                    {{ $t('charging-point.unlock-connector') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded @click="refreshStatus(evse)">
                    {{ $tc('charging-point.refresh-status') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded @click="changeAvailability('operative', evse)">
                    {{ $t('charging-point.change-availability-operative') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn outlined rounded @click="changeAvailability('inoperative', evse)">
                    {{ $t('charging-point.change-availability-inoperative') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="2"  :key="'d-' + evse.id"  v-if="i % 2 === 0">
              <div class="center" v-if="i === 0"></div>
            </v-col>
          </template>
        </v-row>

      </div>



    </div>


    <headline>

    </headline>
    <v-row class="btn-actions my-10">
      <v-col cols="12" sm="4" v-if="currentUser.permissions.includes('STATION_CONFIGURATION')">
        <v-btn outlined rounded block @click="openConfigurations">
          {{ $t('charging-point.configurations') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" v-if="currentUser.permissions.includes('STATION_CONFIGURATION')">
        <v-btn outlined rounded block @click="openFirmware" disabled>
          {{ $t('charging-point.upgrade-firmware') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" v-if="currentUser.permissions.includes('STATION_CONFIGURATION')">
        <v-btn outlined rounded block @click="openDiagnostics" disabled>
          {{ $t('charging-point.request-diagnostics') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" v-if="currentUser.permissions.includes('STATION_CONFIGURATION')">
        <v-btn outlined rounded block @click="clearCache">
          {{ $t('charging-point.clear-cache') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn outlined rounded block @click="reset('Soft')">
          {{ $t('charging-point.soft-reset') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn outlined rounded block color="red" @click="reset('Hard')">
          {{ $t('charging-point.hard-reset') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn outlined rounded block color="red" @click="refreshStatus()">
          {{ $tc('charging-point.refresh-status', 2) }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4" v-if="currentUser.permissions.includes('STATION_CONFIGURATION')">
        <v-btn outlined rounded block color="red" @click="requestBootNotification()">
          {{ $t('charging-point.request-bootnotification') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn outlined rounded block @click="changeAvailability('operative')">
          {{ $t('charging-point.change-availability-operative') }}
        </v-btn>
      </v-col>
      <v-col cols="12" sm="4">
        <v-btn outlined rounded block @click="changeAvailability('inoperative')">
          {{ $t('charging-point.change-availability-inoperative') }}
        </v-btn>
      </v-col>
    </v-row>


    <ConfigurationsModal ref="configurationsModal" :charging-point="chargingPoint" />

  </div>
</template>

<script>
import {EventBus} from "@includes/EventBus";
import SessionRepository from "@repository/SessionRepository";
import CommandRepository from "@repository/CommandRepository";
import ConfigurationsModal from "@pages/ChargingPoint/Detail/modals/ConfigurationsModal";
import SessionStopWatch from "@blocks/SessionStopWatch.vue";

export default {
  components: {
    SessionStopWatch,
    ConfigurationsModal,
  },

  data() {
    return {
      sessions: {},
      configurationsModal: false,
    }
  },

  created() {
    this.meterValuesToken = null
    this.statusNotificationToken = null
  },

  mounted() {
    if(!this.currentUser.permissions.includes('STATION_MANAGE')) {
      this.$router.replace({name: 'charging-points.detail'})
      return
    }

    if(this.chargingPoint) {
      this.loadData()
      this.meterValuesToken = EventBus.$on('on-MeterValues-' + this.chargingPoint.id, this.onNotification)
      this.statusNotificationToken = EventBus.$on('on-StatusNotification-' + this.chargingPoint.id, this.onNotification)
    }
  },

  destroyed() {
    EventBus.$off(this.meterValuesToken)
    EventBus.$off(this.statusNotificationToken)
  },

  watch: {
    chargingPoint() {
      this.loadData()
      if(this.meterValuesToken) {
        EventBus.$off(this.statusNotificationToken)
      }
      if(this.meterValuesToken) {
        EventBus.$off(this.meterValuesToken)
      }
      this.meterValuesToken = EventBus.$on('on-MeterValues-' + this.chargingPoint.id, this.onNotification)
      this.statusNotificationToken = EventBus.$on('on-StatusNotification-' + this.chargingPoint.id, this.onNotification)
    }
  },

  computed:  {
    chargingPoint() {
      return this.$parent.chargingPoint
    },
  },

  methods: {
    loadData() {
      SessionRepository.lastByChargingPoint(this.chargingPoint.id).then(sessions => {
        this.sessions = sessions;
      })
    },

    sessionForEvse(id) {
      return this.sessions[id] || {}
    },


    formatCustomer(id) {
      let session = this.sessionForEvse(id);
      if(!session || !session.customer) {
        return '-'
      }
      let name = ''
      if(session.customer.firstname) {
        name = session.customer.firstname
      }
      if(session.customer.lastname) {
        if(name) {
          name += ' '
        }
        name = session.customer.lastname
      }
      return name
    },

    openConfigurations() {
      this.$refs.configurationsModal.open()
    },

    openFirmware() {

    },

    openDiagnostics() {

    },

    openUnlockConnector(evseId) {

      let evse = this.chargingPoint.evses.find(e => e.id === evseId)
      let connectorId = null;
      if(evse.connectors.length > 0 && evse.connectors[0].connectorId) {
        connectorId = evse.connectors[0].connectorId
      }
      if(!connectorId) {
        this.$dialog.notify.error("ConnectorId required")
      } else {
        CommandRepository.unlockConnector(evseId, connectorId).then(result => {
          this.$dialog.notify.success(this.$t("charging-point.command-sended"))
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
        })
      }

    },

    startSession(evseId) {
      CommandRepository.remoteStartSession(evseId).then(result => {
        this.sessions[evseId] = null
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    stopSession(evseId) {
      if(this.sessionForEvse(evseId)) {
        this.sessionForEvse(evseId).status = "PAUSED"
        CommandRepository.remoteStopSession(evseId, this.sessionForEvse(evseId).id).then(result => {
          this.$dialog.notify.success(this.$t("charging-point.command-sended"))
        }).catch(err => {
          this.$dialog.notify.error(err.response?.data?.message || err.message)
        })
      }
    },

    unlockConnector(evseId) {
      CommandRepository.unlockConnector(evseId).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    cancelReservation(evseId) {
      CommandRepository.cancelReservation(evseId).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    changeAvailability(type, evse = null) {
      let connectorId = null
      if(evse?.connectors?.length > 0) {
        connectorId = evse?.connectors[0]?.connectorId;
      }
      CommandRepository.changeAvailability(this.chargingPoint.id, type, connectorId).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    reset(type) {
      CommandRepository.reset(this.chargingPoint.id, type).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    clearCache() {
      CommandRepository.clearCache(this.chargingPoint.id).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    refreshStatus(evse = null) {
      let connectorId = null
      if(evse?.connectors?.length > 0) {
        connectorId = evse?.connectors[0]?.connectorId;
      }
      CommandRepository.triggerMessage(this.chargingPoint.id, 'StatusNotification', connectorId).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    requestBootNotification() {
      CommandRepository.triggerMessage(this.chargingPoint.id, 'BootNotification').then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    getConfigurations() {
      CommandRepository.getConfiguration(this.chargingPoint.id).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    changeConfigurations(data) {
      CommandRepository.changeConfigurations(this.chargingPoint.id, data).then(result => {
        this.$dialog.notify.success(this.$t("charging-point.command-sended"))
      }).catch(err => {
        this.$dialog.notify.error(err.response?.data?.message || err.message)
      })
    },

    onNotification() {
      this.loadData()
    }
  },
}
</script>

<style lang="scss">
.cp-manage {
  display: flex;
  justify-content: center;
  .center {
    background: url(~@images/dessin-borne.svg);
    background-repeat: no-repeat;
    background-position: center 30px;
    width: 250px;
    min-height: 500px;
  }
  .evses {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .connector {
      width: 100%;
      position: relative;
      font-size: 30px;
      text-align: center;
      font-weight: bold;
      color: #758093;
      padding-bottom: 15px;
      border-bottom: 1px #E53C22 dotted;
      .evse-title {
        font-size: 22px;
        span {
          margin-left: 10px;
          color: #E53C22;
          display: inline-block;
          margin-top: 40px;
        }
      }
      .type {
        color: #5F6569;
        font-size: 18px;
        font-weight: 500;
        text-align: center;

      }

      .plug {
        position: relative;
        width: 62px;
        left: 50%;
        transform: translateX(-50%);
        overflow: hidden;
        text-align: center;
        img {
          width: 55px;
          position: relative;
          z-index: 1;
          top: 5px;
        }
        &:after  {
          content: "";
          position: absolute;
          z-index: 0;
          top: 0;
          transform: translateX(-50%);
          left: 50%;
          right: 0;
          background: white;
          width: 62px;
          height: 62px;
          border-radius: 50%;

        }
      }

    }

    .grid {
      font-size: 18px;
      width: 90%;
      .row {
        .col {
          font-weight: 500;
          color: #E53C22;
          padding: 0 12px;
        }
        .label {
          color: #758093;
          font-weight: 600;
          justify-content: flex-end;
        }
      }
    }
    .btn-actions.row {
      & > div {
        display: flex;
        justify-content: center;
        button {
          flex: 1;
          min-height: 60px;
          font-size: 22px;
          letter-spacing: 0;
          color: #778091;
        }
      }
    }
  }
}

</style>