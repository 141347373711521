import Vue from 'vue'

class ChargingPointEnergyPriceRepository {
    search(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/charging-point-energy-price/search',
                method: 'post',
                data,
            }).then(response => {
                resolve(response.data)
            }).catch(err => {
                reject(err)
            })
        })
    }

    create(data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/charging-point-energy-price/create',
                method: 'post',
                data
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    update(id, data) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/charging-point-energy-price/update/' + id,
                method: 'post',
                data
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }

    delete(id) {
        return new Promise((resolve, reject) => {
            Vue.auth.fetch({
                url: '/api/charging-point-energy-price/delete/' + id,
                method: 'delete',
            })
                .then(response => {
                    resolve(response.data)
                }).catch(err => {
                reject(err)
            })
        })
    }
}

export default new ChargingPointEnergyPriceRepository()
