import Vue from 'vue'

class CommandRepository {

  remoteStartSession(evseId, token) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/remote-start-session',
        method: 'post',
        data: {
          evseId,
          token
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  remoteStopSession(evseId, sessionId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/remote-stop-session',
        method: 'post',
        data: {
          evseId,
          sessionId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  unlockConnector(evseId, connectorId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/unlock-connector',
        method: 'post',
        data: {
          evseId,
          connectorId
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  cancelReservation(evseId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/cancel-reservation',
        method: 'post',
        data: {
          evseId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changeAvailability(chargingPointId, type, connectorId = null) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/change-availability',
        method: 'post',
        data: {
          chargingPointId,
          type,
          connectorId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getConfiguration(chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/get-configuration',
        method: 'post',
        data: {
          chargingPointId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  changeConfigurations(chargingPointId, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/change-configuration',
        method: 'post',
        data: {
          chargingPointId,
          data,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  reset(chargingPointId, type = 'soft') {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/reset',
        method: 'post',
        data: {
          chargingPointId,
          type,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  clearCache(chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/clear-cache',
        method: 'post',
        data: {
          chargingPointId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  triggerMessage(chargingPointId, messageType, connectorId = null) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/trigger-message',
        method: 'post',
        data: {
          chargingPointId,
          messageType,
          connectorId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  getDiagnostic(chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/diagnostics',
        method: 'post',
        data: {
          chargingPointId,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  updateFirmware(chargingPointId, firmware) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point/commands/update-firmware',
        method: 'post',
        data: {
          chargingPointId,
          firmware,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

}


export default new CommandRepository()