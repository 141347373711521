<template>
  <div class="subscription-licences">


    <subscription-licences-list :charging-point-id="chargingPoint.id"></subscription-licences-list>

  </div>
</template>

<script>


import SubscriptionLicencesList from "@/components/elements/SubscriptionLicencesList.vue"

export default {
  components: {
    SubscriptionLicencesList,
  },

  data() {
    return {

    }
  },



  mounted() {

  },

  watch: {

  },

  computed: {
    chargingPoint() {
      return this.$parent.chargingPoint ?? null
    },

  },

  methods: {

  },

}
</script>

<style lang="scss">

</style>
