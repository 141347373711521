<template>
  <div class="charging-point-energy-price-module">
    <v-row dense>
      <v-col>
        <template v-if="readonly">
          {{ currentPrice}}
        </template>
        <v-text-field
            v-else
            :value="currentPrice"
            disabled
            hide-details
        >
          <template #append>
            /kWh
          </template>
        </v-text-field>
      </v-col>
      <v-col class="flex-grow-0 pt-4 pl-3" v-if="!readonly">
        <v-btn
            x-small
            @click="edit"
        >
          <v-icon small left>mdi-pencil</v-icon>
          {{ $t('edit') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="800">
      <v-card>
        <v-card-title>
          {{ $t('price-component.energy-price') }}
        </v-card-title>
        <v-divider class="mb-6"></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-data-table
                  :headers="headers"
                  :items="items"
                  hide-default-footer
              >
                <template #item.effectiveDate="{value}">
                  {{ value|formatDate($t('format_date'))}}
                </template>

                <template #item.vatIncluded="{value}">
                  <v-icon v-if="value">mdi-check</v-icon>
                  <v-icon v-else>mdi-close</v-icon>
                </template>


                <template #item.priceComponents="{item}">
                  <template v-if="item.priceComponents.length > 1">
                    <v-row dense v-for="(priceComponent, i) in item.priceComponents" :key="i">
                      <v-col class="d-flex align-center">
                        {{ priceComponent.price }} €/kWh (> {{ priceComponent.time }})
                        <v-chip v-if="priceComponent.includesWeekend" x-small class="mx-1">{{ $t('price-component.weekend') }}</v-chip>
                        <v-chip v-if="priceComponent.includesPublicHoliday" x-small class="mx-1">{{ $t('price-component.public-holiday') }}</v-chip>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-else>
                    {{ item.priceComponents[0].price }}  €/kWh
                  </template>
                </template>

                <template #item.actions="{item}">
                  <v-btn icon small @click="editPriceComponent(item)">
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn icon small @click="deletePriceComponent(item)">
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-row class="ma-0">
            <v-col>
              <v-btn small @click="createPriceComponent">
                <v-icon small left>mdi-plus</v-icon>
                {{ $t('add') }}
              </v-btn>
            </v-col>
            <v-col class="text-right">
              <v-btn small @click="close">
                <v-icon small left>mdi-close</v-icon>
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>



    <v-dialog v-model="formDialog" max-width="600" v-if="priceComponentForm">
      <v-form @submit.prevent="save">
        <v-card>
          <v-card-title>
            {{ $t('price-component.energy-price') }}
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col>
                <date-picker
                    :label="$t('price-component.effective-date')"
                    v-model="priceComponentForm.effectiveDate"
                    type="date"
                    hide-details
                ></date-picker>
              </v-col>
              <v-col cols="auto">
                <v-switch
                    :label="$t('price-component.vat-included')"
                    v-model="priceComponentForm.vatIncluded"
                    type="switch"
                    hide-details
                ></v-switch>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>{{ $tc('price-component.price-component', 2) }}</h3>
                <v-row v-if="priceComponentForm.priceComponents.length === 0">
                  <v-col>
                    {{ $t('price-component.no-price') }}
                  </v-col>
                </v-row>
                <v-row dense v-for="(priceComponent, i) in priceComponentForm.priceComponents" :key="'price-component-' + i">
                  <v-col v-if="priceComponentForm.priceComponents.length > 1">
                    <v-text-field
                        :label="$t('price-component.time')"
                        type="time"
                        v-model="priceComponentForm.priceComponents[i].time"
                        hide-details
                    />
                  </v-col>
                  <v-col>
                    <v-text-field
                          :label="$t('price')"
                        type="number"
                        v-model.number="priceComponentForm.priceComponents[i].price"
                        hide-details
                    />
                  </v-col>
                  <template v-if="priceComponentForm.priceComponents.length > 1">
                    <v-col cols="auto">
                      <v-checkbox
                          :label="$t('price-component.weekend')"
                          v-model="priceComponentForm.priceComponents[i].includesWeekend"
                          hide-details
                      />
                    </v-col>
                    <v-col cols="auto">
                      <v-checkbox
                          :label="$t('price-component.public-holiday')"
                          v-model="priceComponentForm.priceComponents[i].includesPublicHoliday"
                          hide-details
                      />
                    </v-col>
                    <v-col class="flex-grow-0 align-end d-flex">
                      <v-btn icon small @click="deleteComponent(i)">
                        <v-icon small>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="text-right">
                <v-btn x-small @click="addPriceComponent">
                  <v-icon small left>mdi-plus</v-icon>
                  {{ $t('add') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-row class="ma-0">
              <v-col class="text-right">
                <v-btn class="mr-3" small @click="closeForm">{{ $t('cancel') }}</v-btn>
                <v-btn small color="primary" type="submit" :disabled="!canSave || loadingSave" :loading="loadingSave">{{ $t(isEdit ? 'update' : 'add') }}</v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
  import ChargingPointEnergyPriceRepository from "@repository/ChargingPointEnergyPriceRepository";
  import dayjs from "dayjs";

  export default {

    data() {
      return {
        dialog: false,
        formDialog: false,
        priceComponentForm: null,
        items: [],
        loadingSave: false,
        priceComponents: [],
      }
    },

    props: {
      chargingPointId: String,
      readonly: Boolean,
    },

    mounted() {
      this.loadItems();
    },

    computed: {

      headers() {
        return [
          { text: this.$t('price-component.effective-date'), value: 'effectiveDate' },
          { text: this.$t('price-component.vat-included'), value: 'vatIncluded' },
          { text: this.$t('price'), value: 'priceComponents', align: 'left' },
          { value: 'actions' },
        ]
      },

      canSave() {
        return this.priceComponentForm?.priceComponents.length > 0 && this.priceComponentForm.effectiveDate && this.priceComponentForm.priceComponents.every(pc => pc.time && pc.price > 0);
      },

      id() {
        return this.priceComponentForm?.id ?? null
      },

      isEdit() {
        return !!this.id;
      },

      currentPrice() {
        const item = this.items.find(item => dayjs(item.effectiveDate).isSameOrBefore(dayjs(), 'day'));
        if (item) {
          return item.priceComponents.map(pc => `${pc.price} €` + (item.priceComponents.length > 1 ? ` (${pc.time})` : '')).join(', ');
        }
        return '-';
      }

    },

    methods: {

      edit() {
        this.open()
      },

      open() {
        this.dialog = true
      },

      close() {
        this.dialog = false
      },


      openForm() {
        this.formDialog = true;
      },

      closeForm() {
        this.formDialog = false
      },

      loadItems() {
        this.showLoading(true)
        ChargingPointEnergyPriceRepository.search({chargingPointId: this.chargingPointId}).then(response => {
          this.items = response;
          this.showLoading(false)
        }).catch(e => {
          this.showLoading(false)
          this.notifyError(e)
        });
      },

      addPriceComponent() {
        this.priceComponentForm.priceComponents.push({
          time: this.priceComponentForm.priceComponents.length === 0 ? '00:00' : null,
          price: null,
        });
      },

      deleteComponent(index) {
        this.$dialog.confirm({
          title: this.$t('are-you-sure'),
          persistent: true
        }).then((dialog) => {
          if (dialog) {
            this.priceComponentForm.priceComponents.splice(index, 1);
          }
        });
      },

      save() {

        if(this.priceComponentForm.priceComponents.length === 1) {
          this.priceComponentForm.priceComponents[0].time = '00:00';
          this.priceComponentForm.priceComponents[0].includesWeekend = false;
          this.priceComponentForm.priceComponents[0].includesPublicHoliday = false;
        }

        this.showLoading(true)
        if(!this.isEdit){
          ChargingPointEnergyPriceRepository.create({...this.priceComponentForm, chargingPointId: this.chargingPointId}).then(() => {
            this.formDialog = false;
            this.showLoading(false)
            this.loadItems();
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          });
        } else {
          ChargingPointEnergyPriceRepository.update(this.id, this.priceComponentForm).then(() => {
            this.formDialog = false;
            this.showLoading(false)
            this.loadItems();
          }).catch(err => {
            this.showLoading(false)
            this.notifyError(err)
          });
        }

      },

      createPriceComponent() {
        this.priceComponentForm = {
          effectiveDate: dayjs().format('YYYY-MM-DD'),
          vatIncluded: false,
          priceComponents: [
            { time: '00:00', price: null}
          ],
        }
        this.openForm()
      },

      editPriceComponent(item) {
        this.priceComponentForm = this.cloneObj(item)
        this.openForm()
      },

      deletePriceComponent(item) {
       this.$dialog.confirm({
         text: this.$t('are-you-sure'),
       }).then((dialog) => {
         if (dialog) {
           ChargingPointEnergyPriceRepository.delete(item.id).then(() => {
             this.loadItems();
           }).catch(err => {
             this.notifyError(err)
           });
         }
       });
      },

    }
  }
</script>

<style>
.charging-point-energy-price-module {
  width: 100%;
}
</style>