<template>
  <div class="chargepoint-prices">
    <v-row>
      <v-col class="left">
        <img src="@images/dessin-borne.svg" />
      </v-col>
      <v-col class="right">
        Gestion des tarifs
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {

}
</script>

<style lang="scss">
  .chargepoint-prices {
    .left {
      width: 300px;
      flex: unset;
    }
    .right {
      flex: 1;
      margin: 40px 40px;
      border: 1px solid #ccc;
      background: white;
      box-shadow: inset 0 0 15px 6px #ccc;
      padding: 40px;
    }
  }
</style>