import { render, staticRenderFns } from "./ChargingPointDetailManage.vue?vue&type=template&id=48b5aa84"
import script from "./ChargingPointDetailManage.vue?vue&type=script&lang=js"
export * from "./ChargingPointDetailManage.vue?vue&type=script&lang=js"
import style0 from "./ChargingPointDetailManage.vue?vue&type=style&index=0&id=48b5aa84&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports