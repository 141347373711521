<template>


  <div class="cp-usage">
    <div class="cp-fiche">


      <div class="left mt-10">
        <v-data-table
            class="usage-list"
            :headers="headers"
            :items="items"
            :options.sync="options"
            :server-items-length="total"
            :loading="loading"
        >
          <template #item.startTime="{value}">
            {{ value|formatDate }}
          </template>

          <template #item.stopTime="{value}">
            {{ value|formatDate }}
          </template>

          <template #item.evse.type="{value}">
            {{ value ? $t('enums.connector-type.' + value) : '-' }}
          </template>

          <template #item.duration="{value}">
            <span style="white-space: nowrap" v-if="value">
              {{ formatDuration(value) }}
            </span>
          </template>

          <template #item.energy="{value}">
            {{ value/1000 }} kWh
          </template>

          <template #item.price="{item}">
            {{ item.price ? item.price.toFixed(2) + ' €' : '-' }}
          </template>
        </v-data-table>

        <v-row v-if="false">
          <v-btn outlined rounded color="green">
            Exporter
          </v-btn>
        </v-row>

      </div>

      <div class="right">
        <img src="@images/dessin-borne.svg" />
      </div>

    </div>
  </div>
</template>

<script>
import {EventBus} from "@includes/EventBus";

export default {
  data() {
    return {
      total: 0,
      items: [],
      loading: false,
      options: {
        itemsPerPage: 10,
        page: 1,
        sortBy: ['createdAt'],
        sortDesc: [true],
      }
    }
  },



  mounted() {

  },

  destroyed() {
    EventBus.$off('on-MeterValues-' + this.chargingPoint.id)
  },

  watch: {
    chargingPoint() {
      this.loadData()
    },

    options() {
      this.loadData()
    }
  },

  computed:  {
    chargingPoint() {
      return this.$parent.chargingPoint
    },
    location(){
      return this.$parent.location
    },
    headers() {
      return [
        {text: this.$t("usages.start-time"), value: 'startTime'},
        {text: this.$t("usages.end-time"), value: 'stopTime'},
        {text: this.$t("usages.plug"), value: 'evse.type'},
        {text: this.$t("usages.duration"), value: 'duration'},
        {text: this.$t("usages.kwh"), value: 'energy'},
        // {text: this.$t("usages.total-price"), value: 'price'},
      ]
    },
  },

  methods: {
    loadData() {
      this.loading = true

      const data = {};
      if (this.location !== undefined && this.location !== null) {
        data.locationExternalId = this.location.id;
      }
      if (this.chargingPoint !== undefined && this.chargingPoint!== null) {
        data.chargingPointId = this.chargingPoint.id;
      }

      if (this.options.itemsPerPage > -1) {
        data.offset = (this.options.page * this.options.itemsPerPage) - this.options.itemsPerPage
      }
      data.limit = this.options.itemsPerPage

      if (this.options.sortBy.length > 0) {
        data.orderBy = this.options.sortBy[0]
      }

      if (this.options.sortDesc.length > 0) {
        data.orderDirection = this.options.sortDesc[0] ? 'desc' : 'asc'
      }

      this.$auth.fetch({
        url: '/api/charging/list-sessions',
        method: 'post',
        data
      }).then(response => {
        this.total = response.data.total
        this.items = response.data.items
        this.loading = false
      }).catch(e => {
        this.$dialog.notify.error(e.response?.data?.message || e.message)
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss">
.cp-usage {
  .cp-fiche {
    display: flex;


    .left {
      flex: 1;
    }

    .right {
      flex: 0;
    }

  }
}

</style>
