import Vue from 'vue'

class SessionRepository {

  lastByChargingPoint(chargingPointId) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging/last-charging-point-sessions/' + chargingPointId,
        method: 'get',
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  usagesHistogram() {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging/usages/histogram',
        method: 'post',
        data: {
          tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      }).then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }


}


export default new SessionRepository()