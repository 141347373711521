<template>
  <div class="custormers-search pa-10">
    <title-page icon="ico-carplug">
      {{ $t('charging-point-type.search') }}
    </title-page>


    <v-row>
      <v-col>
        <search-field
            :placeholder="$t('charging-point-type.search')"
            :search.sync="search"
            clearable
        />
      </v-col>
      <v-col cols="auto">
        <v-autocomplete
            v-model="vendor"
            :items="vendors"
            :label="$t('vendor')"
            @change="loadData"
            clearable
        />
      </v-col>
    </v-row>

    <v-row no-gutters class="mt-10">
      <v-col>
        <v-data-table
            class="cp-list"
            :headers="headers"
            :items="items"
            :loading="loading"
            @click:row="item => $router.push({name: 'charging-point-types.detail', params: {id: item.id}})"
        >
          <template #item.modelLabel="{item}">
            <router-link :to="{name: 'charging-point-types.detail', params: {id: item.id}}">
              <span class="identifier">{{ item.modelLabel }}</span>
            </router-link>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>


import ChargingPointTypeRepository from "@repository/ChargingPointTypeRepository";


export default {
  components: {

  },
  data() {
    return {
      loading: false,
      search: null,
      items: [],
      vendors: [],
      vendor: null,
    }
  },

  mounted() {
    if(!this.currentUser.permissions.includes('OPERATOR_READ')) {
      this.$router.replace({name: 'dashboard'})
    }

    this.loadData()
  },

  watch: {
    search(val) {
      this.loadData()
    },

  },

  computed: {
    headers() {
      return [
        {text: this.$t('model'), value: 'modelLabel'},
        {text: this.$t('vendor'), value: 'vendorLabel'},
        {text: this.$t('location.stations-count'), value: 'count'},
      ]
    },

  },

  methods: {

    loadData() {
      let options = this.options

      this.loading = true
      let query = this.search;
      let vendorLabel = this.vendor


      this.loading = true
      ChargingPointTypeRepository.search({
        searchText: query,
        vendorLabel,
      }).then(result => {
        this.items = result.items
        this.vendors = result.vendors.map(item => ({text: item.name + ' (' + item.count + ')', value: item.name}))
        this.loading = false
      }).catch(err => {
        this.$dialog.notify.error(this.$t(err.response?.data?.message || err.message))
        this.loading = false
      })
    },


  }
}
</script>

<style lang="scss">


</style>
