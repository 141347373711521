<template>
  <div v-if="evse">
      {{ evse.startTime ? time : '-' }}
  </div>
</template>

<script>
import dayjs from "dayjs";

export default {

  data() {
    return {
      time: null,
    }
  },

  props: {
    evse: Object,
  },

  mounted() {
    this.interval = setInterval(() => {
      if(!this.paused) {
        if(this.active) {
          let duration = dayjs().diff(dayjs(this.evse.startTime), 'seconds')
          if(duration) {
            this.time = dayjs.duration(duration, 'seconds').format(this.$t('charging-point.evse_format_duration'))
          }
        } else  {
          if(this.evse?.duration) {
            this.time = dayjs.duration(this.evse.duration, 'seconds').format(this.$t('charging-point.evse_format_duration'))
          }
        }
      }
    }, 1000)
  },

  destroyed() {
    clearInterval(this.interval)
  },

  computed: {
    paused() {
      return this.evse.status === 'PAUSED'
    },
    active() {
      if(!this.evse) {
        return false
      }
      return this.evse.status === 'ACTIVE'
    }
  },

  methods: {
    convertToTime() {

    }
  }
}
</script>

<style>

</style>