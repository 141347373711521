<template>
  <div class="charging-point-detail pa-10 pt-0">

    <tab-navigation
      :title="$t('charging-point.charging-point')"
      icon="ico-charging-point"
      :breadcrumbs="breadcrumbs"
    >
      <v-tabs>
        <v-tab :to="{name: 'charging-points.detail'}" exact>{{ $t('charging-point.informations') }}</v-tab>
        <v-tab v-if="currentUser.permissions.includes('STATION_MANAGE')" :to="{name: 'charging-points.detail.manage'}">{{ $t('charging-point.manage') }}</v-tab>
        <v-tab v-if="currentUser.permissions.includes('REPORT_USAGE')" :to="{name: 'charging-points.detail.usages'}">{{ $t('charging-point.usage') }}</v-tab>
        <v-tab v-if="currentUser.permissions.includes('STATION_ISSUE')" :to="{name: 'charging-points.detail.issues'}">{{ $t('issues.issues') }}</v-tab>
        <v-tab v-if="currentUser.permissions.includes('OPERATOR_WRITE')" :to="{name: 'charging-points.detail.licences'}">{{ $tc('subscription.licence', 2) }}</v-tab>
        <v-tab v-if="currentUser.permissions.includes('STATION_LOG')" :to="{name: 'charging-points.detail.messages'}">{{ $t('charging-point.logs') }}</v-tab>
      </v-tabs>
    </tab-navigation>

    <div class="cp-header" v-if="chargingPoint">
      <div class="title">
        {{ chargingPoint.name }}
      </div>
      <div class="statut">
        <div class="span-text">
          {{ $t('charging-point.charging-point-status') }}
        </div>
        <div class="chip">
          <v-row justify="center" v-if="chargingPoint.evses">
            <div :key="`evse-${i}`" class="mx-3 my-5"  v-for="(evse, i) in chargingPoint.evses">
              <chip-status
                  @click="selectEvse(evse)"
                  :value="evse.status"
                  :charging-point-id="chargingPoint.id"
                  :connector-ids="evse.connectors.map(c => c.connectorId)"
              /><br />
              {{ evse.internalId }}
            </div>
          </v-row>
        </div>
      </div>
    </div>

    <router-view></router-view>

  </div>
</template>

<script>


import ChargingPointRepository from "@repository/ChargingPointRepository";
import {EventBus} from "@includes/EventBus";
import PrevRouteMixin from "@mixins/PrevRouteMixin.vue";
import TabNavigation from "@blocks/TabNavigation.vue";

export default {

  components: {
    TabNavigation,
  },

  data() {
    return {
      chargingPoint: null,
      theme: 6
    }
  },

  mixins: [
    PrevRouteMixin
  ],

  created() {
    this.statusNotificationToken = null
  },

  mounted() {
    if(!this.currentUser.permissions.includes('STATION_READ')) {
      this.$router.replace({name: 'dashboard'})
      return
    }
    this.loadData()
  },

  destroyed() {
    if(this.statusNotificationToken) {
      EventBus.$off(this.statusNotificationToken)
    }
  },


  computed: {
    breadcrumbs() {
      let breadcrumbs =  [
          {text: this.$t('charging-point.charging-points'), to: {name: 'charging-points'}, exact: true },
      ]

      if(this.chargingPoint) {
        if(this.chargingPoint.organization) {
          breadcrumbs.push({text: this.chargingPoint.organization?.name ?? '-', to: {name: 'organizations.charging-points', params: {id: this.chargingPoint.organization?.id}}, exact: true})
        }
        // breadcrumbs.push({text: $t('charging-point.charging-points'), to: {name: 'organizations.charging-points', params: {id: chargingPoint.organization.id}}, exact: true})

        breadcrumbs.push({text: this.chargingPoint.name})
      }

      return breadcrumbs
    }
  },

  methods: {
    loadData() {
      this.showLoading(true)
      ChargingPointRepository.get(this.$route.params.id).then(chargingPoint => {
        if(!chargingPoint.chargingPointType) {
          chargingPoint.chargingPointType = {
            id: null,
          }
        }

        this.chargingPoint = chargingPoint

        if(this.statusNotificationToken) {
          EventBus.$off(this.statusNotificationToken)
        }
        this.statusNotificationToken = EventBus.$on('on-StatusNotification-' + this.chargingPoint.id, this.onStatusNotification)

        this.showLoading(false)
      }).catch(err => {
        this.$dialog.notify.error(err.response.data.message ?? err.message)
        this.showLoading(false)
      })
    },



    selectEvse(evse) {

    },

    onStatusNotification(data) {
      if(this.chargingPoint) {
        this.chargingPoint.evses.forEach(e => {
          e.connectors.forEach(c => {
            if(c.connectorId === data.message.connectorId) {
              e.status = this.ocppStatusToStatus(data.message.connectorStatus)
            }
          })
        })
      }
    }


  }
}
</script>

<style lang="scss">
.charging-point-detail {
  flex: 1;
  display: flex;
  flex-direction: column;
  .nav-page {
    display: flex;
    padding-left: 0 !important;
    list-style: none;
    li {
      & + li {
        margin-left: 20px;
      }
    }
  }

  .cp-header {
    .title {
      text-align: center;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
    }
    .statut {
      text-align: center;
      position: relative;
      .span-text {
        font-size: 16px;
        color: #E53C22;
        text-transform: uppercase;
        font-weight: bold;
        display: block;
        margin: 5px 0;
      }
      .chip {
        background-image: url(~@images/bg-gradient.png);
        background-repeat: no-repeat;
        background-position: 0 30px;
        background-size: 100%;
        font-weight: 500;
      }
    }
  }


}
</style>
