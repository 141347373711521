<template>
  <div class="charging-point-search pa-10">

    <title-page icon="ico-charging-point">
      {{ $t("charging-point.search-charging-point") }}
    </title-page>

    <charging-point-list />

  </div>
</template>

<script>

import ChargingPointList from "@/components/elements/ChargingPointList.vue";


export default {
  name: 'ChargingPointSearch',
  components: {
    ChargingPointList,
  },

  mounted() {
    if(!this.currentUser.permissions.includes('STATION_READ')) {
      this.$router.replace({name: 'dashboard'})
      return null
    }
  },

}
</script>

<style lang="scss">

</style>