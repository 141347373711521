<template>
  <div class="pa-10">
      <charging-point-list :chargingPointTypeId="chargingPointType.id"/>

  </div>
</template>

<script>

import ChargingPointList from "@/components/elements/ChargingPointList.vue";

export default {
  components: {ChargingPointList},
  computed: {
    chargingPointType() {
      return this.$parent.chargingPointType ?? null
    },


  },

  methods: {


  }
}
</script>

<style lang="scss">
.evses {
  .row {
    margin: -4px;
    &  + .row {
      margin-top: 4px;
    }
    & > .col, & > [class*=col-] {
      padding: 4px;
      &:not(.label) {
        justify-content: center;
      }
    }
  }
  .v-expansion-panel {
    background: white;
  }
  .evse {
    //border: 1px dotted #ccc;
    border-radius: 10px;
    padding: 0 20px;
    .sub-headline {
      margin: 20px 0;
      font-weight: 500;
    }
    .physical-reference {
      border: 2px solid var(--v-primary-base);
      color: var(--v-primary-base);
      border-radius: 15px;
      padding: 0px 10px;
      font-size: 16px;
      line-height: 22px;
      height: 26px;
      font-weight: bold;
      box-shadow: 0 0 15px #e53c225e;

    }
    .connectors {
      .connector {
        margin: 0 30px;
        border-radius: 10px;
        .grid {
          .headline-title {
            .col {
              color: var(--v-primary-base);
              font-weight: 600;
              display: flex;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

</style>
