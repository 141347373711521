<template>
  <div class="charging-point-type-edit pa-10">


    <title-page icon="ico-status-charging" class="mb-0">
      {{ $t("charging-point-type.edit") }}
    </title-page>

    <div class="mt-5">
      <headline>
        {{ $t('charging-point-type.charging-point-type') }}
      </headline>
      <v-form class="form-grid" ref="form" @submit.prevent="save">

        <v-row justify="center">
          <v-col>
            <form-field
                :label=" $t('model')"
                v-model="chargingPointType.model"
                disabled
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('vendor')"
                v-model="chargingPointType.vendor"
                disabled
            />
          </v-col>

        </v-row>

        <v-row justify="center">
          <v-col>
            <form-field
                :label=" $t('model-label')"
                v-model="chargingPointType.modelLabel"
                :rules="[commonRules.required]"
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('vendor-label')"
                v-model="chargingPointType.vendorLabel"
                :rules="[commonRules.required]"
            />
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <form-field
                :label=" $t('charging-point-type.max-power')"
                v-model.number="chargingPointType.maxPower"
                :rules="[commonRules.required]"
                type="number"
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('charging-point-type.dynamic-load-management-support')"
                v-model="chargingPointType.dynamicLoadManagementSupport"
                type="switch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <form-field
                :label=" $t('charging-point-type.charging-profile-support')"
                v-model="chargingPointType.chargingProfileSupport"
                type="switch"
            />
          </v-col>
          <v-col>
            <form-field
                :label=" $t('charging-point-type.status-notification-slave')"
                v-model="chargingPointType.statusNotificationSlave"
                type="switch"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <form-field
                type="select"
                :label="$t('charging-point.capabilities')"
                v-model="chargingPointType.capabilities"
                :items="capabilities"
                item-value="value"
                item-text="label"
                multiple
                chips
            />
          </v-col>
        </v-row>
        <v-row justify="center" class="evses">
          <v-col>
            <div class="evse" v-for="(evse, i) in chargingPointType.evses" :key="`evse-${i}`">
              <headline dotted>
                {{ $t('charging-point.charging-point') }} {{ i + 1 }}
              </headline>

              <v-row justify="center" class="connectors mt-5" >
                <v-col>
                  <v-row
                      v-for="(connector, j) in evse.connectors"
                      :key="'connectorId-' + j"
                      class="connector"
                      align="center"
                  >

                    <v-col class="num-col">
                      <v-text-field
                          :label="$t('charging-point.connector-id')"
                          v-model.number="connector.connectorId"
                          :rules="[commonRules.required]"
                          type="number"
                      />
                    </v-col>

                    <v-col>
                      <v-row>
                        <v-col cols="4" lg="3">
                          <v-select
                              :label=" $t('charging-point.connector-type')"
                              v-model="connector.type"
                              :items="enumsToList($t('enums.connector-type'), true)"
                              :rules="[commonRules.required]"
                          />
                        </v-col>

                        <v-col cols="4" lg="2">
                          <v-select
                              :label=" $t('charging-point.format')"
                              v-model="connector.format"
                              :items="enumsToList($t('enums.connector-format'))"
                              :rules="[commonRules.required]"
                          />
                        </v-col>

                        <v-col cols="4" lg="2">
                          <v-select
                              :label=" $t('charging-point.power-type')"
                              v-model="connector.powerType"
                              :items="enumsToList($t('enums.power-type'))"
                              :rules="[commonRules.required]"
                          />
                        </v-col>
                        <v-col cols="3" lg="1">
                          <v-text-field
                              :label=" $t('charging-point.voltage')"
                              v-model.number="connector.voltage"
                              min="0"
                              :rules="[commonRules.required]"
                              type="number"
                          >
                            <template #append>V</template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="3" lg="1">
                          <v-text-field
                              :label=" $t('charging-point.power')"
                              v-model.number="connector.power"
                              :rules="[commonRules.required]"
                              min="0"
                              type="number"
                          >
                            <template #append>kW</template>
                          </v-text-field>
                        </v-col>

                        <v-col cols="3" lg="1">
                          <v-text-field
                              :label="$t('charging-point.amperage')"
                              v-model.number="connector.amperage"
                              min="0"
                              :rules="[commonRules.required]"
                              type="number"
                          >
                            <template #append>A</template>
                          </v-text-field>

                        </v-col>

                        <v-col cols="3" lg="2">
                          <v-select
                              :label="$t('charging-point.charging-mode')"
                              v-model="connector.chargingMode"
                              :items="enumsToList($t('enums.charging-mode'))"
                              :rules="[commonRules.required]"
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col class="del-col">
                      <v-btn icon small @click="deleteConnector(i, j)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>

              <v-row>
                <v-col>
                  <v-btn @click="addConnector(i)" small>
                    <v-icon left>mdi-plus</v-icon>
                    {{ $t('charging-point.add-connector') }}
                  </v-btn>
                </v-col>
              </v-row>

            </div>

            <v-row>
              <v-col class="text-right">
                <v-btn @click="addEvse()" small>
                  <v-icon left>mdi-plus</v-icon>
                  {{ $t('charging-point.add-evse') }}
                </v-btn>
              </v-col>
            </v-row>

          </v-col>

        </v-row>
        <v-row>
          <v-col class="d-flex justify-center">
            <v-btn class="mr-5" @click="cancel">
              {{ $t('cancel') }}
            </v-btn>
            <v-btn color="primary" type="submit">
              {{ $t('save') }}
            </v-btn>
          </v-col>

        </v-row>
      </v-form>
    </div>

  </div>
</template>

<script>


import RulesMixin from "@mixins/RulesMixin";
import ChargingPointTypeRepository from "@repository/ChargingPointTypeRepository";

export default {


  mixins: [RulesMixin],

  watch: {

    chargingPointType: {
      handler() {
        this.setUnsavedChanges()
      },
      deep: true
    }
  },

  computed: {
    chargingPointType() {
      return this.$parent.chargingPointType ?? null
    },


    capabilities() {
      return Object.keys(this.$t('enums.charging-point-capability')).map(key => {
        return {
          value: key,
          label: this.$t('enums.charging-point-capability.' + key),
        }
      })
    },
  },
  methods: {
    save() {
      if(this.$refs.form.validate()) {
        this.showLoading(true)
        ChargingPointTypeRepository.update(this.chargingPointType.id, {
          ...this.chargingPointType,
        }).then(data => {
          this.$parent.chargingPointType = data
          //this.$router.replace({name: 'charging-point-types.detail'})
          this.$dialog.notify.success(this.$t('update-success'))
          this.showLoading(false)
          this.$nextTick(() => {
            this.cleanUnsavedChanges()
          })
        }).catch(err => {
          this.$dialog.notify.error(err.response.data.message || err.message)
          this.showLoading(false)
        })
      }
      //
    },
    cancel() {
      this.$router.replace({name: 'charging-point-types'})
    },
    addEvse() {
      if(!this.chargingPointType.evses) {
        this.$set(this.chargingPointType, 'evses', [])
      }
      this.chargingPointType.evses.push({
        num: this.chargingPointType.evses.length + 1,
        customId: null,
        physicalReference: null,
        parkingRestriction: null,
        connectors: [{connectorId: this.getNextConnector()}]
      })
    },
    getNextConnector() {
      let maxConnectorId = 0;
      if(this.chargingPointType.evses) {
        this.chargingPointType.evses.forEach(evse => {
          evse.connectors.forEach(connector => {
            if(connector.connectorId > maxConnectorId) {
              maxConnectorId = Number(connector.connectorId)
            }
          })
        })
      }

      return maxConnectorId + 1
    },
    addConnector(evseIndex) {
      this.chargingPointType.evses[evseIndex].connectors.push({connectorId: this.getNextConnector()})
    },

    deleteConnector(evseIndex, connectorIndex) {
      this.$dialog.confirm({
        text: this.$t('charging-point.confirm-delete-connector')
      }).then(result => {
        if(result) {
          this.chargingPointType.evses[evseIndex].connectors.splice(connectorIndex, 1)
          if(this.chargingPointType.evses[evseIndex].connectors.length === 0) {
            this.chargingPointType.evses.splice(evseIndex, 1)
          }
        }

      })
    },
  },
}
</script>

<style lang="scss">
.connectors {

  .connector {
    .label {
      color: var(--v-primary-base);
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
    }
    & + .connector {
      border-top: 1px dotted #ccc;
      margin-top: 10px;
    }
    .num-col {
      max-width: 60px;
      input {
        text-align: center;
      }
    }
    .del-col {
      max-width: 60px;
    }
  }

}
</style>
