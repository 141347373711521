import Vue from 'vue'

class ChargingPointTypeRepository {

  get(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type/get/' + id,
        method: 'get',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  update(id, data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type/update/' + id,
        method: 'post',
        data
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  search(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type/search',
        method: 'post',
        data,
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }

  chargingPointTypeVendors(query = '') {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type-vendor/search',
        method: 'get',
        data: {
          query,
        },
      })
      .then(response => {
        resolve(response.data)
      }).catch(err => {
        reject(err)
      })
    })
  }
  delete(id) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type/' + id,
        method: 'delete',
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

  create(data) {
    return new Promise((resolve, reject) => {
      Vue.auth.fetch({
        url: '/api/charging-point-type',
        method: 'post',
        data,
      })
          .then(response => {
            resolve(response.data)
          }).catch(err => {
        reject(err)
      })
    })
  }

}


export default new ChargingPointTypeRepository()
