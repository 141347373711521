<template>
  <div class="pa-10 pt-0" v-if="chargingPointType">


    <tab-navigation
        :title="$tc('charging-point-type.charging-point-type')"
        icon="mdi-counter"
        :breadcrumbs="breadcrumbs"
    >
      <v-tabs>
        <v-tab :to="{name: 'charging-point-types.detail'}" exact>{{ $t('information') }}</v-tab>
        <v-tab :to="{name: 'charging-point-types.charging-points'}" exact>{{ $t('charging-point.charging-points') }}</v-tab>
      </v-tabs>
    </tab-navigation>




    <router-view></router-view>

  </div>
</template>

<script>
import ChargingPointTypeRepository from "@repository/ChargingPointTypeRepository";
import TabNavigation from "@blocks/TabNavigation.vue";

export default {
  name: 'ChargingPointTypeDetail',
  components: {TabNavigation},
  data() {
    return {
      chargingPointType: null,
    }
  },

  mounted() {
    this.loadData()
  },

  watch: {
    id() {
      this.loadData()
    }
  },

  computed: {

    id() {
      return this.$route.params.id
    },

    breadcrumbs() {
      let breadcrumbs = [{text: this.$tc('charging-point-type.charging-point-type'), to: {name: 'charging-point-types'}, exact: true}]
      if(this.chargingPointType) {
        breadcrumbs.push({text: this.chargingPointType.modelLabel})
      }

      return breadcrumbs
    },

    capabilities() {
      return this.chargingPointType?.capabilities.map(key => {
        return {
          value: key,
          label: this.$t('enums.charging-point-capability.' + key),
        }
      }) ?? []
    },

  },

  methods: {
    loadData() {
      if(this.id) {
        this.showLoading(true)
        ChargingPointTypeRepository.get(this.id).then(chargingPointType => {
          this.chargingPointType = chargingPointType
          this.showLoading(false)
        }).catch(err => {
          this.$dialog.notify.error(err.response.data?.message || err.message)
          this.showLoading(false)
        })
      }
    },
  },

}
</script>

<style lang="scss">


</style>
