<template>
  <div>
    <v-autocomplete
        ref="input"
        v-model="data"
        :label="label"
        :loading="loading"
        :items="items"
        v-on="$listeners"
        :rules="rules"
        item-text="fullname"
        item-value="id"
        :search-input.sync="searchText"
        :solo="solo"
        :clearable="clearable"
        :append-icon="appendIcon"
        :readonly="readonly"
        :disabled="disabled"
        :placeholder="placeholder"
        @keydown="loadItems(searchText)"
        no-filter
        hide-details
        hide-no-data
        autocomplete="nope"
    >
      <template #item="{item, on, attrs}">
        <v-list-item v-on="on" v-bind="attrs">
          <v-list-item-content>
            <v-list-item-title>
              {{ item.fullname }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.customer.evcoId }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template #selection="{item}" v-if="!selectionWithNoExtra">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.customer.firstname ?? "" }} {{ item.customer.lastname ?? "" }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ item.customer.evcoId }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-autocomplete>
  </div>
</template>

<script>

import MemberRepository from "@repository/MemberRepository";

export default {
  data(){
    return {
      loading: false,
      items: [],
      data: this.value,
      searchText: "",
    }
  },

  model: {
    prop: 'value',
    event: 'input',
  },

  props: {
    value: String,
    type: String,
    clearable: Boolean,
    readonly: Boolean,
    disabled: Boolean,
    solo: Boolean,
    rules: Array,
    appendIcon: String,
    placeholder: String,
    label: String,
    organizationId: String,
    selectionWithNoExtra: Boolean,
  },

  watch: {

    data(val) {
      this.$emit('input', val)
    },

    value(val) {
      this.data = val
      this.loadItems(val)
    },

    organizationId() {
      if(this.searchText) {
        this.searchText = ''
      } else {
        this.loadItems('')
      }
    }
  },

  mounted() {
    this.loadItems(this.data)
  },

  computed: {

  },

  methods: {
    loadItems(searchText) {
      this.loading = true
      MemberRepository.search({
        organizationId: this.organizationId,
        searchText: searchText,
        limit: 50,
      }).then(result => {
        this.items = result.items.map(item => ({
          ...item,
          fullname: `${item.customer.firstname} ${item.customer.lastname}`,
        }))
        this.loading = false
      }).catch(err => {
        this.notifyError(err)
      })
    },

    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="scss">

</style>